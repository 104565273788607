// lib
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Modal from 'react-modal';
import { connect } from "react-redux";
import {
    removeInsuredArticle,
    getRestrictions,
    offerProductById,
    checkOutOfferHomeById,
    getPolicyInfo,
} from '../../actions';
import Header from "../../components/generalComponents/header/Header";
import './DetailsPolicy.css'
import AccesosRapidos from "../../components/homeComponents/AccesosRapidos/AccesosRapidos";
import AtencionAlert from "../../components/alertComponents/AtencionAlert/AtencionAlert";
import DesactivarPolizaAlert from "../../components/alertComponents/DesactivarPolizaAlert/DesactivarPolizaAlert";
import SiniestroAlert from "../../components/alertComponents/SiniestroAlert/SiniestroAlert";
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import VerMasModal from "../../components/modalComponents/VerMasModal/VerMasModal";
import { estadoList } from "../../util/EstadoListColors";
import PaymentCard from "./PaymentCard/PaymentCard";
import PaymentCbu from "./PaymentCbu/PaymentCbu";
import DesactivarPolizaSuccessAlert from "../../components/alertComponents/DesactivarPolizaSuccessAlert/DesactivarPolizaSuccessAlert";


let isTabletOrMobile = false;

const DetailsPolicy = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
    const { id }: any = useParams();
    const [productPackage, setProductPackage] = useState<any>(null);
    const [activeSwitch, setActiveSwitch] = useState<boolean>(false);
    const [siniestroAlert, setSiniestroAlert] = useState<boolean>(false);
    const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
    const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
    const [verMasAlert, setVerMasAlert] = useState<boolean>(false);
    const [verMasInfo, setVerMasInfo] = useState<any>(null);
    const [checkoutData, setCheckoutData] = useState<any>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [policy, setPolicy] = useState<any>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [desactivarAlertSuccess, setDesactivarAlertSuccess] = useState<boolean>(false);

    const handleUrlParams = () => {
        const checkoutId = searchParams.get('checkoutId');
        if(checkoutId) checkOutOfferHomeById(checkoutId, setCheckoutData)
    };

    useEffect(() => {
        if(checkoutData) {
            setProductPackage(checkoutData?.offer?.packages?.filter((a) => a?.id === checkoutData?.offer?.currentPackage)[0])
        }
    }, [checkoutData])

    useEffect(() => {
        if(policy) {
          setActiveSwitch(policy?.enable)
        }
    }, [policy])

    useEffect(() => {
      getPolicyInfo(id, setPolicy)
      handleUrlParams()
    }, [refresh])

    useEffect(() => {
      getPolicyInfo(id, setPolicy)
      handleUrlParams()
    }, []);

    const siniestroModal = () => {
        return (
          <Modal
            isOpen={siniestroAlert}
            onRequestClose={() => setSiniestroAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <SiniestroAlert
              close={() => setSiniestroAlert(false)}
            />
          </Modal>
        )
      }

      const verMasModal = () => {
        return (
          <Modal
            isOpen={verMasAlert}
            onRequestClose={() => setVerMasAlert(false)}
            style={{
              content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '25px',
                  width: isTabletOrMobile ? '80%' : '50%',
                  padding: '20px'
              },
              overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }
            }}
          >
            <VerMasModal
              info={verMasInfo}
              close={() => setVerMasAlert(false)}
            />
          </Modal>
        )
      }
    
      const desactivarModal = () => {
        return (
          <Modal
            isOpen={desactivarAlertId !== -1}
            onRequestClose={() => setDesactivarAlertId(-1)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <DesactivarPolizaAlert
              desactivarAlert={()=> setDesactivarAlertId(-1)}
              desactivarPressed={() => {
                props.removeInsuredArticle(desactivarAlertId, setRefresh, refresh, setDesactivarAlertSuccess)
                setDesactivarAlertId(-1)
              }}
              reDeactivate={policy?.enable && estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.status === 'CANCEL_REQUESTED'}
            />
          </Modal>
        )
      }

      const desactivarSuccessModal = () => {
        return (
          <Modal
            isOpen={desactivarAlertSuccess}
            onRequestClose={() => setDesactivarAlertSuccess(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <DesactivarPolizaSuccessAlert
              dismiss={()=> setDesactivarAlertSuccess(false)}
            />
          </Modal>
        )
      }
    
      const atencionModal = () => {
        return (
          <Modal
            isOpen={atencionAlert}
            onRequestClose={() => setAtencionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <AtencionAlert
              close={() => setAtencionAlert(false)}
            />
          </Modal>
        )
    }

    const getProductRegionId = (categoryId: any) => {
        switch (categoryId) {
          case 18:
            return 9242;
          case 19:
            return 9243;
          case 20:
            return 9244;
          default:
            return 9242;
        }
    }

    const paymentCard: any = {
      "simple_creditcard": { component: PaymentCard },
      "cbu": { component: PaymentCbu },
      "realcash": { component: PaymentCbu },
      undefined: { component: PaymentCard }
    }

    const PaymentComponent: any = policy?.paymentInfoResponseDTO?.paymentType ? paymentCard[policy?.paymentInfoResponseDTO?.paymentType].component : null;

    return (
        <div className="detailsPolicy-container">
            <Header home colored special />
            <div className="detailsPolicy-container-main">
                <div className="detailsPolicy-container-main-left">
                    <h4 className="detailsPolicy-container-main-left-title bold">Detalle de cobertura</h4>
                    <div className="detailsPolicy-container-main-left-product">
                        <div className="detailsPolicy-container-main-left-product-imgWrap">
                            <img className="detailsPolicy-container-main-left-product-imgWrap-img" src={checkoutData?.offer?.product?.image} />
                        </div>
                        <p className="detailsPolicy-container-main-left-product-text bold">{checkoutData?.offer?.product?.title + ' - ' + productPackage?.name}</p>
                        <div 
                            className="detailsPolicy-container-main-left-product-switch"
                            onClick={() => {
                                if(policy?.enable) setDesactivarAlertId(id)
                                else props.offerProductById(id, getProductRegionId(checkoutData?.offer?.product?.categoryId), null, navigate, props.countryId)
                            }}
                            style={{ backgroundColor: activeSwitch ? 'var(--secondary500)' : 'var(--gray200)'}}
                        >
                            <div 
                                className="detailsPolicy-container-main-left-product-switch-ball"
                                style={{ transform: activeSwitch ? 'translate(25px, 0px)' : 'translate(5px, 0px)' }}
                            ></div>
                        </div>
                    </div>
                    <div className="detailsPolicy-container-main-left-poliza">
                        <b className="bold large" style={{ color: 'var(--gray700)' }}>N° {id}</b>
                        <div className="detailsPolicy-container-main-left-poliza-number">
                            <b className="bold large" style={{ color: 'var(--gray700)' }}>Estado:&nbsp;</b>
                            <b className="bold large" style={{ color: estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color }}>{estadoList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}</b>
                        </div>
                    </div>
                    {!isTabletOrMobile &&
                      <>
                        {policy?.paymentInfoResponseDTO?.paymentType && 
                          <>
                            <h6 className="detailsPolicy-container-main-left-payTitle bold">Medio de pago</h6>
                            <PaymentComponent policy={policy} userData={props.userData}/>
                          </>
                        }
                        <h6 className="bold" style={{ color: 'var(--gray700)'}}>¿Cómo podemos ayudarte?</h6>
                        <div className="detailsPolicy-container-main-left-links">
                            <AccesosRapidos
                                text='Denunciar un siniestro'
                                icon='e911_emergency'
                                disable
                                onPressed={() => setSiniestroAlert(true)}
                            />
                            <AccesosRapidos
                                text='Atención al cliente'
                                icon='contact_support'
                                onPressed={() => setAtencionAlert(true)}
                            />
                        </div>
                      </>
                    }
                </div>
                <div className="detailsPolicy-container-main-right">
                    <div className="detailsPolicy-container-main-right-title">
                        <h6 className="bold" style={{ color: 'var(--gray700)' }}>¿Cuál es tu cobertura?</h6>
                        <b 
                            className="bold large"
                            style={{ color: 'var(--primary500)', cursor: 'pointer' }}
                            onClick={() => props.getRestrictions(checkoutData?.offer?.product?.categoryId, checkoutData?.offer?.id, checkoutData?.offer?.packages[0]?.id, setVerMasAlert, setVerMasInfo)}
                        >VER DETALLE</b>
                    </div>
                    <div className="detailsPolicy-container-main-right-list">
                        {productPackage?.coverageGroups[0]?.coverages?.map((a: any, index: any) => (
                            <div className="detailsPolicy-container-main-right-list-item" key={index}>
                                <h6 className="detailsPolicy-container-main-right-list-item-title regular">{a.title}</h6>
                                <h6 className="detailsPolicy-container-main-right-list-item-value regular">{a.value}</h6>
                            </div>
                        ))}
                    </div>
                    {isTabletOrMobile && 
                      <>
                        {policy?.paymentInfoResponseDTO?.paymentType && 
                          <>
                            <h6 className="detailsPolicy-container-main-left-payTitle bold">Medio de pago</h6>
                            <PaymentComponent policy={policy} userData={props.userData}/>
                          </>
                        }
                        <h6 className="bold" style={{ color: 'var(--gray700)'}}>¿Cómo podemos ayudarte?</h6>
                        <div className="detailsPolicy-container-main-left-links">
                            <AccesosRapidos
                                text='Denunciar un siniestro'
                                icon='e911_emergency'
                                disable
                                onPressed={() => setSiniestroAlert(true)}
                            />
                            <AccesosRapidos
                                text='Atención al cliente'
                                icon='contact_support'
                                onPressed={() => setAtencionAlert(true)}
                            />
                        </div>
                      </>
                    }
                    {policy?.enable && <div className="detailsPolicy-container-main-right-bot">
                        <MainButton
                            id='SOLICITAR LA BAJA'
                            border
                            color='var(--secondary500)'
                            text='SOLICITAR LA BAJA'
                            onButtonPressed={() => setDesactivarAlertId(id)}
                        />
                    </div>}
                </div>
            </div>
            {atencionModal()}
            {siniestroModal()}
            {desactivarModal()}
            {verMasModal()}
            {desactivarSuccessModal()}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        countryId: state.general.countryId,
        userData: state.user.userData
    };
};

export default connect(mapStateToProps, {
    removeInsuredArticle,
    getRestrictions,
    offerProductById
})(DetailsPolicy);


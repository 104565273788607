import { useEffect, useRef, useState } from 'react';
import './BurgerMenu.css';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion
} from '../../../actions';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import BurgerMenuItem from './BurgerMenuItem/BurgerMenuItem';
import { burgerMenuList } from '../../../data';

let isTabletOrMobile = false;

const BurgerMenu = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const navigate = useNavigate();
    const mainRef: any = useRef();
    const [translate, setTranslate] = useState<any>(null);
    const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
    const location: any = useLocation();
    const path = location ? location?.pathname : '';

    const avatarSize = isTabletOrMobile ? 100 : 125;

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                props.setBurgerOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        setTranslate(window.screen.width-mainRef?.current?.offsetWidth)
    }, [window?.screen?.width, mainRef])

    return (
        <div
            ref={mainRef}
            className="burgerMenu-container"
            style={{ transform: props.burgerMenuOpen ? `translate(${translate}px, 0px)` : `translate(4000px, 0px)` }}
        >
            <span onClick={() => props.setBurgerOpen(false)} className="material-symbols-outlined" style={{ cursor: 'pointer', fontSize: 40, color: 'var(--white)', marginLeft: 'auto', marginRight: 10, marginTop: 10 }}>close</span>
            <div className="burgerMenu-container-main">
                {burgerMenuList(props, navigate, path, setAtencionAlert)?.map((a: any, index: any) =>(
                    <BurgerMenuItem
                        key={index}
                        label={a.label}
                        action={a.action}
                        disabled={a.disabled}
                    />
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        burgerMenuOpen: state.general.burgerMenuOpen,
        userData: state.user.userData,
        userAccessToken: state.general.userAccessToken,
    };
};

export default connect(mapStateToProps, {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion
})(BurgerMenu);
import React, { useState } from 'react';
import './BurgerMenuItem.css';

const BurgerMenuItem = (props: any) => {

    return (
        <div
            className="burgerMenuItem-container"
            onClick={() => props.action()}
        >
            <h6 className="burgerMenuItem-container-text regular">{props.label}</h6>
        </div>
    );

}

export default BurgerMenuItem;
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import {
  fetchCategoriaList,
  categoriaSelectedChange,
  categoriaQuestion,
  answerQuestion,
  getQuoteQuestions,
  getQuoteQuestionsAlt,
  deleteQuestion
} from '../../actions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import './Cotizar.css'
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
import {Helmet} from "react-helmet";
import GetGlobalForm from '../../components/generalComponents/GlobalForms/getGlobalForm';
import automovilLottie from '../../assets/lotties/automovil.json'
import Lottie from 'react-lottie';
import Modal from 'react-modal';
import NumeroCotizacionModal from '../../components/alertComponents/NumeroCotizacionModal/NumeroCotizacionModal';
import QuoteQuestion from '../../components/cotizarComponents/QuoteQuestion/QuoteQuestion';
import Footer from '../../components/generalComponents/footer/Footer';

let isTabletOrMobile = false;
const Cotizar = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  const [cotizarQuestion, setCotizarQuestion] = useState<any>(null);

  //FORM ANSWER
  const [formAnswer, setFormAnswer] = useState<any>(null);
  
  //ERROR ALERT
  const [numeroCotizacionError, setNumeroCotizacionError] = useState<boolean>(false);
  const [numeroCotizacionErrorText, setNumeroCotizacionErrorText] = useState<string>('');
  
  //OTHER
  const navigate = useNavigate()
  const [admin, setAdmin] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);

  useEffect(() => {
    props.fetchCategoriaList()
  }, [props.countryId])

  useEffect(() => {
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('Not-Emitting', appBaseIframe)
    } else {
      window.parent.postMessage('Not-Emitting', 'http://localhost:3000')
    }
    handleUrlParams()
  }, [])

  useEffect(() => {
    if(cotizarQuestion?.previousQuestions?.length === 0) {
      if(window.location.hostname != 'localhost') {
        window.parent.postMessage('CanGoBack', appBaseIframe)
      } else {
        window.parent.postMessage('CanGoBack', 'http://localhost:3000')
      }
    } else {
      if(window.location.hostname != 'localhost') {
        window.parent.postMessage('Not-CanGoBack', appBaseIframe)
      } else {
        window.parent.postMessage('Not-CanGoBack', 'http://localhost:3000')
      }
    }
  }, [cotizarQuestion])

  const handleUrlParams = () => {
    let categoryId: any = searchParams.get("cId")
    let quote: any = searchParams.get("quote")
    let admin: any = searchParams.get("admin")
    categoryId = parseInt(categoryId)
    if (admin) {
      setAdmin(true)
    }
    if (categoryId && quote) {
      props.categoriaSelectedChange(categoryId)
      props.getQuoteQuestionsAlt(categoryId, quote, setCotizarQuestion)
    } else if (categoryId && !quote) {
      props.categoriaSelectedChange(categoryId)
      props.categoriaQuestion(categoryId, navigate, props.countryId, admin, setCotizarQuestion)
    }
  }

  const handleContinuarButton = (data: any, isLast: boolean) => {
    if (cotizarQuestion) {
      props.answerQuestion(props.categoriaSelected, cotizarQuestion.id, data, navigate, props.countryId, admin, setLoadingOffer, setNumeroCotizacionError, setNumeroCotizacionErrorText, appBaseIframe, setCotizarQuestion, isLast)
    } else {
      props.categoriaQuestion(data, navigate, props.countryId, admin)
    }
  }

  const numeroCotizacionModal = () => {
    return (
      <Modal
        isOpen={numeroCotizacionError}
        onRequestClose={() => setNumeroCotizacionError(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '25%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.50)'
          }
        }}
      >
        <NumeroCotizacionModal
          close={() => setNumeroCotizacionError(false)}
          text={numeroCotizacionErrorText}
        />
      </Modal>
    )
  }

  return (
    <div className="cotizar-container" >
      <Helmet>
        <html lang={useIntl().formatMessage({ id: 'meta.cotizar.html' })}/>
        <title>{useIntl().formatMessage({ id: 'meta.cotizar.title'}, { name: props.categorias?.filter((a: any) => a.category.id === props.categoriaSelected)[0]?.category.name } )}</title>
        <meta name="description" content={useIntl().formatMessage({ id: 'meta.cotizar.description' })} />
        <meta property='keywords' content={useIntl().formatMessage({ id: 'meta.cotizar.keywords' })} />
      </Helmet>
      {(!admin && !loadingOffer) && <Header home />}
      {loadingOffer ? 
        <div className="cotizar-container-mainLoading">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: automovilLottie
            }}
            height={isTabletOrMobile ? 100 : 150}
            width={isTabletOrMobile ? 100 : 150}
          />
          <h4 className="cotizar-container-mainLoading-text regular">Buscando los mejores planes para tu hogar...</h4>
        </div>
        :
        <div 
          className="cotizar-container-main"
          style={{
            width: admin ? '100%' : '93.3%',
            marginTop: admin ? 0 : 20
          }}
        >
          <QuoteQuestion 
            data={cotizarQuestion} 
            canGoBack
            goBack={() => {
              if(cotizarQuestion?.previousQuestions?.length === 0) {
                const adminN = admin ? '?admin=True' : ''
                navigate('/inicio' + adminN)
              }
              else props.deleteQuestion(props.categoriaSelected, cotizarQuestion?.id, cotizarQuestion?.previousQuestions[cotizarQuestion?.previousQuestions?.length - 1]?.step, setFormAnswer, setCotizarQuestion)
            }}
          />
          {cotizarQuestion?.incompleteQuestions?.map((a: any) => (
            <GetGlobalForm
              parent='Cotizar'
              key={a?.id}
              item={a}
              currentAnswer={formAnswer}
              next={handleContinuarButton}
              setLocalAnswers={setFormAnswer}
            />
          ))}
        </div>
      }
      {(!loadingOffer && !admin) && <Footer />}
      {numeroCotizacionModal()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categorias: state.cotizar.categorias,
    categoriaSelected: state.cotizar.categoriaSelected,
    countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  fetchCategoriaList,
  categoriaSelectedChange,
  categoriaQuestion,
  answerQuestion,
  getQuoteQuestions,
  getQuoteQuestionsAlt,
  deleteQuestion
})(Cotizar);
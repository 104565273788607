import React, { useEffect, useState } from 'react'
import './ContactExito.css'
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import contactanos from '../../assets/images/contactanos.png';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import {store} from '../../index';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import Lottie from 'react-lottie';
import emailLottie from '../../assets/lotties/email-successfully-sent.json'

const ContactExito = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const navigate = useNavigate();

  const countryId = store.getState().general.countryId

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional, adds a smooth scrolling effect
    });
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className="contactExito-container">
        <Header home colored fixed trans={scrollPosition > 30} special />      
        <div className="contactExito-container-main">
        <div className="contactExito-container-main-left">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: emailLottie
            }}
            height={150}
            width={150}
          />
          <h4 className="contactExito-container-main-left-title regular">¡Tu mensaje fue enviado!</h4>
          <b className="contactExito-container-main-left-subTitle regular large">Muy pronto nos pondremos en contacto.<br/>Gracias por tu consulta.</b>
          <div className="contactExito-container-main-left-buttonWrapper">
            <MainButton
              id='Volver'
              text='VOLVER AL HOME'
              onButtonPressed={() => navigate(GetCountryUrl(countryId) + 'inicio')}
            />
          </div>
        </div>
        <div className="contactExito-container-main-right">
          <img className="contactExito-container-main-right-img" src={contactanos}/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactExito;


import React from 'react'
import './DesactivarPolizaAlert.css'
import { ReactSVG } from 'react-svg';
import CaraTriste from '../../../assets/images/svgs/caraTriste.svg'
import Exit from '../../../assets/images/svgs/exit.svg'
import MainButton from '../../generalComponents/MainButton/MainButton';

const DesactivarPolizaAlert = (props: any) => {
  return (
    <div className="desactivarPolizaAlert-container">
        <span 
            onClick={() => props.desactivarAlert()} 
            className="material-symbols-outlined" 
            style={{ 
                fontSize: 32,
                cursor: 'pointer',
                marginLeft: 'auto'
            }}
        >close</span>
        {props.reDeactivate ? 
            <span className="material-symbols-outlined" style={{ color: 'var(--secondary200)', fontSize: 80 }}>schedule</span>
            :
            <span className="material-symbols-outlined" style={{ color: 'var(--primary200)', fontSize: 80 }}>sentiment_dissatisfied</span>
        }
        {props.reDeactivate ? 
            <p className="desactivarPolizaAlert-container-text bold">Ya hay una solicitud de baja en proceso.<br/>En breve nos comunicaremos para gestionarla.</p>
            :
            <p className="desactivarPolizaAlert-container-text bold">¿Confirmás que querés<br/>solicitar la baja de tu cobertura?</p>
        }
        {props.reDeactivate ? 
            <MainButton
                id='ACEPTAR'
                fill
                color='var(--secondary500)'
                text='ACEPTAR'
                onButtonPressed={() => props.desactivarAlert()}
            />
            :
            <div className="desactivarPolizaAlert-container-button">
                <MainButton
                    id='VOLVER'
                    empty
                    color='var(--secondary500)'
                    text='VOLVER'
                    onButtonPressed={() => props.desactivarAlert()}
                />
                <MainButton
                    id='ACEPTAR'
                    fill
                    color='var(--secondary500)'
                    text='ACEPTAR'
                    onButtonPressed={() => props.desactivarPressed()}
                />
            </div>
        }
    </div>
  );
}

export default DesactivarPolizaAlert;
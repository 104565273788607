import React, {useEffect, useState} from 'react'
import './FormFieldPersonDatosFinaer.css'
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';
import { useMediaQuery } from 'react-responsive';
import Moment from 'moment/moment'
import { actionPersonDataFinearLists, actionUserData } from '../../../../../actions';

let isTabletOrMobile = false;
const FormFieldPersonDatosFinaer = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs Dueño 
    const [nombreDueño, setNombreDueño] = useState<string>('');
    const [apellidoDueño, setApellidoDueño] = useState<string>('');
    const [fechaNacDueño, setFechaNacDueño] = useState<string>('');

    const [tipoDocuDueño, setTipoDocuDueño] = useState<any>(null);
    const [numDocuDueño, setNumDocuDueño] = useState<string>('');
    const [sexoDueño, setSexoDueño] = useState<any>(null);
    
    const [estadoCivilDueño, setEstadoCivilDueño] = useState<any>(null);
    const [nacionalidadDueño, setNacionalidadDueño] = useState<any>(null);
    const [profesionDueño, setProfesionDueño] = useState<any>(null);

    const [codigoAreaDueño, setCodigoAreaDueño] = useState<string>('');
    const [celularDueño, setCelularDueño] = useState<string>('');
    const [emailDueño, setEmailDueño] = useState<string>('');

    const [codigoPostalDueño, setCodigoPostalDueño] = useState<any>(null);
    const [calleDueño, setCalleDueño] = useState<string>('');
    const [nroDueño, setNroDueño] = useState<string>('');
    const [pisoDueño, setPisoDueño] = useState<string>('');
    const [dptoDueño, setDptoDueño] = useState<string>('');

    //FOCUS
    const [tipoDocuFocus, setTipoDocuFocus] = useState<boolean>(false);
    const [sexoFocus, setSexoFocus] = useState<boolean>(false);
    const [estadoCivilFocus, setEstadoCivilFocus] = useState<boolean>(false);

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [sexoList, setSexoList] = useState<any>(null);

    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);
    const [profesionList, setProfesionList] = useState<any>(null);
    const [codigosPostalesList, setCodigosPostalesList] = useState<any>(null);

    useEffect(() => {
        if(nombreDueño !== '' && apellidoDueño !== '' && isValidDate(fechaNacDueño) &&
            tipoDocuDueño && validDocument(numDocuDueño, tipoDocuDueño) && sexoDueño && 
            estadoCivilDueño && nacionalidadDueño && profesionDueño &&
            codigoAreaDueño?.length >= 1 && celularDueño?.length >= 6 && validateEmail(emailDueño) &&
            codigoPostalDueño && calleDueño?.length > 0 && nroDueño?.length > 0) {
            props.setDisableContinuar(false);
            const data = {
                "nombreDueño": nombreDueño,
                "apellidoDueño": apellidoDueño,
                "fechaNacDueño": fechaNacDueño,

                "tipoDocuDueño": tipoDocuDueño,
                "numDocuDueño": numDocuDueño,
                "sexoDueño": sexoDueño,

                "estadoCivilDueño": estadoCivilDueño,
                "nacionalidadDueño": nacionalidadDueño,
                "profesionDueño": profesionDueño,

                "codigoAreaDueño": codigoAreaDueño,
                "celularDueño": celularDueño,
                "emailDueño": emailDueño,

                "codigoPostalDueño": codigoPostalDueño,
                "calleDueño": calleDueño,
                "nroDueño": nroDueño,
                "pisoDueño": pisoDueño,
                "dptoDueño": dptoDueño
            }
            props.onUpdate(JSON.stringify(data))
        } else props.setDisableContinuar(true)
    }, [nombreDueño, apellidoDueño, fechaNacDueño,
        tipoDocuDueño, numDocuDueño, sexoDueño,
        estadoCivilDueño, nacionalidadDueño, profesionDueño,
        codigoAreaDueño, celularDueño, emailDueño,
        codigoPostalDueño, calleDueño, nroDueño, pisoDueño, dptoDueño
    ])

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    }

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidDate = (e: string) => {
        if(e.length === 10) return Moment(e, 'YYYY-MM-DD').isValid()
        return false
    } 

    const validDocument = (numero: any, tipo: any) => {
        if(tipo === 8 || tipo === 9) { 
            return (numero?.length === 11)
        } return (numero?.length == 8 || numero?.length == 7);
    }

    useEffect(() => {
        hardCodeLists();
        actionPersonDataFinearLists(setNacionalidadList, setProfesionList, setCodigosPostalesList, props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.insurer?.id);
    }, [])

    const hardCodeLists = () => {
        const sexoListAux = [
            {
                descripcion: 'FEMENINO',
                codigo: 'F'
            },
            {
                descripcion: 'MASCULINO',
                codigo: 'M'
            },
            {
                descripcion: 'NO BINARIO',
                codigo: 'X'
            }
        ]
        setSexoList(sexoListAux);

        const estadoCivilListAux = [
            {
                descripcion: 'SOLTERO/A',
                codigo: 1
            },
            {
                descripcion: 'CASADO/A',
                codigo: 2
            },
            {
                descripcion: 'VIUDO/A',
                codigo: 3
            },
            {
                descripcion: 'DIVORCIADO/A',
                codigo: 4
            },
            {
                descripcion: 'SEPARADO/A',
                codigo: 5
            },
            {
                descripcion: 'CONVIVIENTE',
                codigo: 7
            }
        ]
        setEstadoCivilList(estadoCivilListAux);

        const tipoDocuListAux: any = [
            {
                descripcion: 'D.N.I.',
                codigo: 6
            },
            {
                descripcion: 'C.D.I.',
                codigo: 1
            },
            {
                descripcion: 'L.E.',
                codigo: 3
            },
            {
                descripcion: 'L.C.',
                codigo: 4
            },
            {
                descripcion: 'PASAPORTE',
                codigo: 5
            },
            {
                descripcion: 'C.U.I.T.',
                codigo: 8
            },
            {
                descripcion: 'C.U.I.L.',
                codigo: 9
            }
        ]
        setTipoDocuList(tipoDocuListAux);
    }

    return (
        <div className="FormFieldPersonDatosFinaer-container">
            <h6 className="FormFieldPersonDatosFinaer-container-titulo bold">{props.data?.display?.description}</h6>
            <div className="FormFieldPersonDatosFinaer-container-list">
                <CustomTextInput
                    label='Nombre'
                    required
                    changeText={(e: string) => setNombreDueño(e)}
                    value={nombreDueño}
                    error={!isValidName(nombreDueño)}
                    success={nombreDueño !== '' ? isValidName(nombreDueño) : false}
                    shake={(!isValidName(nombreDueño) || nombreDueño === '') && props.shake}
                />
                <CustomTextInput
                    label='Apellido'
                    required
                    changeText={(e: string) => setApellidoDueño(e)}
                    value={apellidoDueño}
                    error={!isValidName(apellidoDueño)}
                    success={apellidoDueño !== '' ? isValidName(apellidoDueño) : false}
                    shake={(!isValidName(apellidoDueño) || apellidoDueño === '') && props.shake}
                />
                <CustomTextInput
                    label='Fecha de nacimiento'
                    required
                    changeText={(e: string) => setFechaNacDueño(e)}
                    value={fechaNacDueño}
                    error={fechaNacDueño?.length === 0 ? false : !isValidDate(fechaNacDueño)}
                    errorMessage={props.checkOutData?.offer?.product?.categoryId == 18 || props.checkOutData?.offer?.product?.categoryId == 19 ? 'Para afiliarte debes tener entre 18 y 65 años' : 'Para afiliarte debes tener entre 50 y 70 años'}
                    success={isValidDate(fechaNacDueño)}
                    maxLength={10}
                    placeholder="DD/MM/AAAA"
                    type="date"
                    max='2999-12-31'
                    shake={(!isValidDate(fechaNacDueño) || fechaNacDueño?.length === 0) && props.shake}
                    onKeyDown={(event: any) => {
                        if (event?.key === 'Tab' || event?.keyCode === 9) {
                            event.preventDefault();
                            setTipoDocuFocus(true)
                        }
                    }}
                />
                <div className={(!tipoDocuDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-selectorWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-selectorWrapper"} style={{ opacity: !tipoDocuList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                        id='Selector Tipo de documento'
                        list={tipoDocuList}
                        onValueSelected={(a: any) => setTipoDocuDueño(a.codigo)}
                        selectedItem={tipoDocuList && tipoDocuDueño ? tipoDocuList?.filter((a: any) => a.codigo == tipoDocuDueño)[0]?.descripcion : ''}
                        placeholder='Seleccionar...'
                        deleteSelectedItem={() => {
                            setNumDocuDueño('')
                            setTipoDocuDueño(null)
                        }}
                        disabled={!tipoDocuList}
                        noSearch
                        focus={tipoDocuFocus}
                    /> 
                </div>
                <CustomTextInput
                    label='Nro de documento'
                    required
                    changeText={(e: string) => setNumDocuDueño(e.replace(/[^0-9]+/g, ''))}
                    value={numDocuDueño}
                    success={validDocument(numDocuDueño, tipoDocuDueño)}
                    placeholder='Ingresa sólo números sin puntos'
                    error={!validDocument(numDocuDueño, tipoDocuDueño) && numDocuDueño?.length !== 0}
                    maxLength={tipoDocuDueño === 8 || tipoDocuDueño === 9 ? 11 : 8}
                    disabled={!tipoDocuDueño}
                    shake={!validDocument(numDocuDueño, tipoDocuDueño) && props.shake}
                    onKeyDown={(event: any) => {
                        if (event?.key === 'Tab' || event?.keyCode === 9) {
                            event.preventDefault();
                            setEstadoCivilFocus(true)
                        }
                    }}
                />
                <div className={(!sexoDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-optionWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-optionWrapper"} style={{ opacity: !sexoList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-optionWrapper-title bold">Sexo que figura en el DNI&nbsp;<ul>*</ul></p>
                    <div className="FormFieldPersonDatosFinaer-container-list-optionWrapper-list" style={{ opacity: !sexoList ? 0.5 : 1 }}>
                        {sexoList?.map((a: any, index: any) => (
                            <div className="FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item" key={index}>
                                <div className="FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-circle" onClick={() => setSexoDueño(a.codigo)}>
                                    <div 
                                        className="FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-circle-inner" 
                                        style={{ transform: a.codigo === sexoDueño ? `scale(${1})` : `scale(${0})` }}
                                    />
                                </div>
                                <p className="FormFieldPersonDatosFinaer-container-list-optionWrapper-list-item-text bold">{a.codigo}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={(!estadoCivilDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-selectorWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-selectorWrapper"} style={{ opacity: !estadoCivilList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                        id='Selector Estado Civil' 
                        list={estadoCivilList}
                        onValueSelected={(a: any) => setEstadoCivilDueño(a.codigo)}
                        selectedItem={estadoCivilList && estadoCivilDueño ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivilDueño)[0]?.descripcion : ''} 
                        placeholder='Seleccionar...' 
                        deleteSelectedItem={() => setEstadoCivilDueño(null)}
                        disabled={!estadoCivilList}
                        focus={estadoCivilFocus}
                        noSearch
                    />
                </div>
                <div className={(!nacionalidadDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-selectorWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-selectorWrapper"} style={{ opacity: !nacionalidadList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                        id='Selector Nacionalidad'
                        list={nacionalidadList}
                        onValueSelected={(a: any) => setNacionalidadDueño(a.codigo)}
                        selectedItem={nacionalidadList && nacionalidadDueño ? nacionalidadList?.filter((a: any) => a.codigo == nacionalidadDueño)[0]?.descripcion : ''}
                        placeholder='Seleccionar...'
                        deleteSelectedItem={() => setNacionalidadDueño(null)}
                        disabled={!nacionalidadList}
                    /> 
                </div>
                <div className={(!profesionDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-selectorWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-selectorWrapper"} style={{ opacity: !profesionList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-selectorWrapper-title bold">Profesión&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                        id='Selector Profesión'
                        list={profesionList}
                        onValueSelected={(a: any) => setProfesionDueño(a.codigo)}
                        selectedItem={profesionList && profesionDueño ? profesionList?.filter((a: any) => a.codigo == profesionDueño)[0]?.descripcion : ''}
                        placeholder='Seleccionar...'
                        deleteSelectedItem={() => setProfesionDueño(null)}
                        disabled={!profesionList}
                    /> 
                </div>
                <div className={((codigoAreaDueño === '' || celularDueño === '') && props.shake) ? "FormFieldPersonDatosFinaer-container-list-customInput shake-animation" : "FormFieldPersonDatosFinaer-container-list-customInput"}>
                    <div className="FormFieldPersonDatosFinaer-container-list-customInput-label">
                        <p className="FormFieldPersonDatosFinaer-container-list-customInput-label-text bold">Teléfono celular</p>
                        <p className="FormFieldPersonDatosFinaer-container-list-customInput-label-requiered bold">&nbsp;*</p>
                    </div>
                    <div className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer">
                        <div className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper" style={{ width: '25%' }}>
                            <b className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-prefix regular large">0</b>
                            <input
                                className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-input"
                                maxLength={5}
                                onChange={(e) => setCodigoAreaDueño(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={codigoAreaDueño}
                            />
                            {codigoAreaDueño?.length >= 1 && <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>}
                        </div>
                        <div className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper" style={{ width: '65%' }}>
                            <b className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-prefix regular large">15</b>
                            <input
                                className="FormFieldPersonDatosFinaer-container-list-customInput-inputContainer-inputWrapper-input"
                                maxLength={8}
                                onChange={(e) => setCelularDueño(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={celularDueño}
                            />
                            {celularDueño?.length > 0 && celularDueño?.length < 6 ? 
                                <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                : celularDueño?.length >= 6 ?
                                <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <CustomTextInput
                    label='E-mail'
                    required
                    value={emailDueño}
                    changeText={(e: string) => setEmailDueño(e)}
                    success={validateEmail(emailDueño)}
                    error={emailDueño?.length > 0 ? !validateEmail(emailDueño) : false}
                    errorMessage='E-mail inválido'
                    placeholder='nombre@ejemplo.com'
                    shake={!validateEmail(emailDueño) && props.shake}
                />
                <div className="FormFieldPersonDatosFinaer-container-list-invis"></div>
            </div>
            <h6 className="FormFieldPersonDatosFinaer-container-titulo bold" style={{ marginTop: 10 }}>Datos de domicilio</h6>
            <div className="FormFieldPersonDatosFinaer-container-list">
            <div className={(!codigoPostalDueño && props.shake) ? "FormFieldPersonDatosFinaer-container-list-selectorWrapper shake-animation" : "FormFieldPersonDatosFinaer-container-list-selectorWrapper"} style={{ opacity: !codigosPostalesList ? 0.5 : 1 }}>
                    <p className="FormFieldPersonDatosFinaer-container-list-selectorWrapper-title bold">Localidad o código postal&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                        id='Selector Localidad o código postal' 
                        list={codigosPostalesList}
                        onValueSelected={(a: any) => setCodigoPostalDueño(a.codigoPostal)}
                        selectedItem={codigosPostalesList && codigoPostalDueño ? codigosPostalesList?.filter((a: any) => a.codigoPostal == codigoPostalDueño)[0]?.descripcion + ' (' + codigosPostalesList?.filter((a: any) => a.codigoPostal == codigoPostalDueño)[0]?.codigoPostal + ')' : ''} 
                        placeholder='Seleccioná tu localidad o código postal' 
                        deleteSelectedItem={() => setCodigoPostalDueño(null)}
                        disabled={!codigosPostalesList}
                        cp
                    /> 
                </div>
                <CustomTextInput
                    label='Calle'
                    required
                    changeText={(e: string) => setCalleDueño(e)}
                    value={calleDueño}
                    success={calleDueño !== ''}
                    shake={calleDueño === '' && props.shake}
                />
                <div className="FormFieldPersonDatosFinaer-container-list-list">
                    <CustomTextInput
                        label='Nro'
                        required
                        changeText={(e: string) => setNroDueño(e.replace(/[^0-9]+/g, ''))}
                        value={nroDueño}
                        success={nroDueño !== ''}
                        shake={nroDueño === '' && props.shake}
                    />
                    <CustomTextInput
                        label='Piso'
                        changeText={(e: string) => setPisoDueño(e)}
                        value={pisoDueño}
                        success={pisoDueño !== ''}
                    />
                    <CustomTextInput
                        label='Dpto.'
                        changeText={(e: string) => setDptoDueño(e)}
                        value={dptoDueño}
                        success={dptoDueño !== ''}
                    />
                </div>
            </div>
        </div>
    );
}

export default FormFieldPersonDatosFinaer;
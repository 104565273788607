import React, { useEffect, useState } from 'react'
import './Politica.css'
import Header from '../../components/generalComponents/header/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useGoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { loginGoogleUser, userAccessTokenSet } from '../../actions';

const CLIENT_ID = '321672482348-5era59e4iuc65ad0sjhs4tsrtjkk59sd.apps.googleusercontent.com';

const Politica = (props: any) => {

  const [admin, setAdmin] = useState<boolean>(false);
  const [checkoutId, setCheckoutId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const onSuccess = (response: any) => {
    setIsLoading(false)
    props.loginGoogleUser(response?.tokenId, props.countryId, checkoutId, navigate)
  };

  const onFailure = (response: any) => {
    setIsLoading(false)
  };

  const navigate = useNavigate();

  const handleUrlParams = () => {
    const cheId = searchParams.get('checkoutId');
    const admin: any = searchParams.get("admin")
    if (admin) setAdmin(true)
    if (cheId) setCheckoutId(cheId)
  };

  useEffect(() => {
    handleUrlParams()
    props.userAccessTokenSet(null)
  }, [])

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: CLIENT_ID,
    cookiePolicy: 'single_host_origin',
    onRequest: () => setIsLoading(true)
  });

  return (
    <div className="politica-container">
      {!admin && <Header home colored special />}
      <div className="politica-container-main">
        <h4 className="politica-container-main-title bold">Iniciar sesión</h4>
        <h6 className="politica-container-main-subTitle bold">Para continuar, necesitamos validar tus datos.</h6>
        <div className="politica-container-main-google">
          <MainButton
            google
            id="Google Sign In"
            onButtonPressed={signIn}
            disabled={isLoading}
            text={isLoading ? "Ingresando..." : "Ingresar con Google"}
          />
        </div>
        <div className="politica-container-main-politics">
          <MainButton
            id="Política de privacidad"
            onButtonPressed={() => navigate('/politica-page')}
            text="Política de privacidad"
            empty
            color="var(--secondary500)"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  loginGoogleUser,
  userAccessTokenSet
})(Politica);
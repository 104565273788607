import React, { useEffect, useState } from 'react'
import './OfertaPersonalizadaEnviado.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import { useMediaQuery } from 'react-responsive';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import Lottie from 'react-lottie';
import emailSent from '../../assets/lotties/emailVerificar.json'

let isTabletOrMobile = false;
const OfertaPersonalizadaEnviado = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [admin, setAdmin] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleUrlParams();
  }, [])

  const handleUrlParams = () => {
    let admin: any = searchParams.get("admin")
    if (admin) setAdmin(true)
  };

  const lottieSize = isTabletOrMobile ? 80 : 80
  
  return (
    <div className="ofertaPersonalizadaEnviado-container">
      {!admin && <Header home colored special />}
      <div className="ofertaPersonalizadaEnviado-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: emailSent,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={lottieSize}
          width={lottieSize}
          isStopped={false}
          isPaused={false}
        />
        <h4 className="ofertaPersonalizadaEnviado-container-main-title regular">¡Tu solicitud fue enviada!</h4>
        <b className="ofertaPersonalizadaEnviado-container-main-subTitle regular large">Muy pronto nos pondremos en contacto.</b>
        <b className="ofertaPersonalizadaEnviado-container-main-subTitle regular large">Gracias por tu consulta.</b>
        <div className="ofertaPersonalizadaEnviado-container-main-buttonWrapper">
          <MainButton
            id='VOLVER AL HOME'
            fill
            color='var(--secondary500)'
            text='VOLVER AL HOME'
            onButtonPressed={() => navigate('inicio')}
          />
        </div>
      </div>
      {!admin && <Footer/>}
    </div>
  )
}

export default OfertaPersonalizadaEnviado;
import React, {useEffect, useState} from 'react'
import './FormFieldPersonFinaer.css'
import MainButton from '../../../MainButton/MainButton';
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';

const FormFieldPersonFinaer = (props: any) => {

    const [telefono, setTelefono] = useState<string>('');
    const [codigoArea, setCodigoArea] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [iva, setIva] = useState<any>(null);
    const [ingresos, setIngresos] = useState<any>(null);

    useEffect(() => {
        if(props.data?.input?.config?.defaults) {
            setIva({ label: props.data?.input?.config?.ivas?.filter((a: any) => a.value === props.data?.input?.config?.defaults?.iva)[0]?.label, value: props.data?.input?.config?.defaults?.iva })
            setIngresos({ label: props.data?.input?.config?.ingresoss?.filter((a: any) => a.value === props.data?.input?.config?.defaults?.ingresos)[0]?.label, value: props.data?.input?.config?.defaults?.ingresos })
        }
    }, [props.data?.input?.config?.defaults])

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isDisabled = () => {
        return telefono?.length < 6 || codigoArea?.length === 0 || !validateEmail(email) || !iva || !ingresos;
    }

    const handleItemSelected = (a: any) => {
        const data = {
          "questionId": props.data.id,
          "value": a,
        }
        props.onUpdateAlt(data, true)
    }

    return (
        <div className="FormFieldPersonFinaer-container">
            <div className="FormFieldPersonFinaer-container-main">
                <div className="FormFieldPersonFinaer-container-main-customInput">
                    <div className="FormFieldPersonFinaer-container-main-customInput-label">
                        <p className="FormFieldPersonFinaer-container-main-customInput-label-text bold">Teléfono celular</p>
                        <p className="FormFieldPersonFinaer-container-main-customInput-label-requiered bold">&nbsp;*</p>
                    </div>
                    <div className="FormFieldPersonFinaer-container-main-customInput-inputContainer">
                        <div className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper" style={{ width: '25%' }}>
                            <p className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-prefix regular">0</p>
                            <input
                                className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-input"
                                maxLength={5}
                                onChange={(e) => setCodigoArea(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={codigoArea}
                            />
                            {codigoArea?.length >= 1 && <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>}
                        </div>
                        <div className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper" style={{ width: '65%' }}>
                            <p className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-prefix regular">15</p>
                            <input
                                className="FormFieldPersonFinaer-container-main-customInput-inputContainer-inputWrapper-input"
                                maxLength={8}
                                onChange={(e) => setTelefono(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={telefono}
                            />
                            {telefono?.length > 0 && telefono?.length < 6 ? 
                                <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                : telefono?.length >= 6 ?
                                <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <CustomTextInput
                    label='Email'
                    required
                    value={email}
                    changeText={(e: string) => setEmail(e)}
                    success={validateEmail(email)}
                    error={email.length > 0 ? !validateEmail(email) : false}
                    errorMessage='E-mail inválido'
                    placeholder='nombre@ejemplo.com'
                />
                <div className="FormFieldPersonFinaer-container-main-selectorList">
                    <div className="FormFieldPersonFinaer-container-main-selectorList-label">
                        <p className="FormFieldPersonFinaer-container-main-selectorList-label-text bold">Condición ante el IVA</p>
                        <p className="FormFieldPersonFinaer-container-main-selectorList-label-requiered bold">&nbsp;*</p>
                    </div>
                    <div className="FormFieldPersonFinaer-container-main-selectorList-selectorWrapper">
                        <SelectorSearcher
                            id='Condición ante el IVA'
                            list={props.data?.input?.config?.ivas}
                            onValueSelected={(a: any) => setIva(a)}
                            selectedItem={iva?.label}
                            placeholder='Seleccioná tu condición ante el IVA'
                            deleteSelectedItem={() => setIva(null)}
                        />
                    </div>
                </div>
                <div className="FormFieldPersonFinaer-container-main-selectorList">
                    <div className="FormFieldPersonFinaer-container-main-selectorList-label">
                        <p className="FormFieldPersonFinaer-container-main-selectorList-label-text bold">Ingresos brutos</p>
                        <p className="FormFieldPersonFinaer-container-main-selectorList-label-requiered bold">&nbsp;*</p>
                    </div>
                    <div className="FormFieldPersonFinaer-container-main-selectorList-selectorWrapper">
                        <SelectorSearcher
                            id='Ingresos brutos'
                            list={props.data?.input?.config?.ingresoss}
                            onValueSelected={(a: any) => setIngresos(a)}
                            selectedItem={ingresos?.label}
                            placeholder='Seleccioná tus ingresos brutos'
                            deleteSelectedItem={() => setIngresos(null)}
                        />
                    </div>
                </div>
            </div>
            <div className="FormFieldPersonFinaer-container-buttonWrapper">
                <MainButton
                    id='Cotizar'
                    text={props.data?.input?.config?.button}
                    fill
                    color='var(--secondary500)'
                    onButtonPressed={() => {
                        const data = {
                            "telefono" : telefono,
                            "codigoArea" : codigoArea,
                            "email": email,
                            "iva": iva?.value,
                            "brutos": ingresos?.value
                        }
                        handleItemSelected(data)
                    }}
                    disabled={isDisabled()}
                />
            </div>
        </div>
    );
}

export default FormFieldPersonFinaer;
import React, {useEffect, useState} from 'react'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import './CheckOutProfile.css'
import {
    createUserFinaer,
    editCreateUserFinaer,
    getUserDataLists,
    V2_getQuote,
    actionGetUserDataFromEmail
} from '../../../actions'
import {connect} from 'react-redux';
import Moment from 'moment/moment'
import { useMediaQuery } from 'react-responsive'
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import SelectorSearcher from '../../generalComponents/selectorSearcher/SelectorSearcher';
import { useSearchParams } from 'react-router-dom';

let isTabletOrMobile = false;
const CheckOutProfile = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs Usuario
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [fechaNac, setFechaNac] = useState<string>('');

    const [tipoDocu, setTipoDocu] = useState<any>(null);
    const [numDocu, setNumDocu] = useState<string>('');
    const [sexo, setSexo] = useState<any>(null);
    
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionalidad] = useState<any>(null);
    const [profesion, setProfesion] = useState<any>(null);

    const [codigoArea, setCodigoArea] = useState<string>('');
    const [celular, setCelular] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [sexoList, setSexoList] = useState<any>(null);

    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);
    const [profesionList, setProfesionList] = useState<any>(null);

    const [codigoPostalList, setCodigoPostalList] = useState<any>(null);

    //FOCUS
    const [tipoDocuFocus, setTipoDocuFocus] = useState<boolean>(false);
    const [sexoFocus, setSexoFocus] = useState<boolean>(false);
    const [estadoCivilFocus, setEstadoCivilFocus] = useState<boolean>(false);
    
    //OTROS
    const [admin, setAdmin] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
    const [quoteData, setQuoteData] = useState<any>(null);
    const [disableShake, setDisableShake] = useState<boolean>(false);
    const [currentPackage, setCurrentPackage] = useState<any>(null);
    const [userDataPreset, setUserDataPreset] = useState<any>(null);
    
    useEffect(() => {
        if(props.checkOutData) {
            V2_getQuote(props.checkOutData?.offer?.product?.categoryId, props.checkOutData?.offer?.quoteId, setQuoteData)
            setCurrentPackage(props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0])
        }
    }, [props.checkOutData])

    useEffect(() => {
        if(quoteData) {
            setEmail(quoteData?.email)
            setCelular(quoteData?.telefono)
            setCodigoArea(quoteData?.codigoArea)
        }
    }, [quoteData])

    useEffect(() => {
        if(validateEmail(email) && admin) {
            actionGetUserDataFromEmail(email, setUserDataPreset)
        }
    }, [email, admin])

    useEffect(() => {
        if(userDataPreset) {
            if(userDataPreset?.name) setNombre(userDataPreset?.name)
            if(userDataPreset?.lastName) setApellido(userDataPreset?.lastName)
            if(userDataPreset?.birthDate) setFechaNac(userDataPreset?.birthDate)

            if(userDataPreset?.documentId) setTipoDocu(userDataPreset?.documentId)
            if(userDataPreset?.documentNumber) setNumDocu(userDataPreset?.documentNumber)
            if(userDataPreset?.sexId) setSexo(userDataPreset?.sexId)

            if(userDataPreset?.civilStatusId) setEstadoCivil(userDataPreset?.civilStatusId)
            if(userDataPreset?.nacionalityId) setNacionalidad(userDataPreset?.nacionalityId)
            if(userDataPreset?.profesion) setProfesion(userDataPreset?.profesion)
        }
    }, [userDataPreset])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        props.getUserDataLists(setCodigoPostalList, setNacionalidadList, setProfesionList, props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.insurer?.id);
        hardCodeLists();
    }, [])

    useEffect(() => {
        if(disableShake) {
            setTimeout(() => {
                setDisableShake(false)
            }, 1000)
        }
    }, [disableShake])

    const hardCodeLists = () => {
        const sexoListAux = [
            {
                descripcion: 'FEMENINO',
                codigo: 'F'
            },
            {
                descripcion: 'MASCULINO',
                codigo: 'M'
            },
            {
                descripcion: 'NO BINARIO',
                codigo: 'X'
            }
        ]
        setSexoList(sexoListAux);

        const estadoCivilListAux = [
            {
                descripcion: 'SOLTERO/A',
                codigo: 1
            },
            {
                descripcion: 'CASADO/A',
                codigo: 2
            },
            {
                descripcion: 'VIUDO/A',
                codigo: 3
            },
            {
                descripcion: 'DIVORCIADO/A',
                codigo: 4
            },
            {
                descripcion: 'SEPARADO/A',
                codigo: 5
            },
            {
                descripcion: 'CONVIVIENTE',
                codigo: 7
            }
        ]
        setEstadoCivilList(estadoCivilListAux);

        const tipoDocuListAux: any = [
            {
                descripcion: 'D.N.I.',
                codigo: 6
            },
            {
                descripcion: 'C.D.I.',
                codigo: 1
            },
            {
                descripcion: 'L.E.',
                codigo: 3
            },
            {
                descripcion: 'L.C.',
                codigo: 4
            },
            {
                descripcion: 'PASAPORTE',
                codigo: 5
            },
            {
                descripcion: 'C.U.I.T.',
                codigo: 8
            },
            {
                descripcion: 'C.U.I.L.',
                codigo: 9
            }
        ]
        setTipoDocuList(tipoDocuListAux);
    }

    useEffect(() => {
        handleUrlParams();
    },[])

    const handleUrlParams = () => {
        const admin: any = searchParams.get("admin");
        if (admin) {
            setAdmin(true)
        }
    };

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    }

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidDate = (e: string) => {
        if(e?.length === 10) return Moment(e, 'YYYY-MM-DD').isValid()
        return false
    } 

    const validDocument = (numero: any, tipo: any) => {
        if(tipo === 8 || tipo === 9) { 
            return (numero?.length === 11)
        } return (numero?.length == 8 || numero?.length == 7);
    }

    const isDisabled = () => {
        if (
            nombre === '' || apellido === '' || !isValidDate(fechaNac) || 
            !tipoDocu || !validDocument(numDocu, tipoDocu) || 
            !sexo || !estadoCivil || !nacionalidad || !profesion ||
            codigoArea?.length === 0 || celular?.length < 6 || !validateEmail(email)
        ) return true
        return false
    }
    
    return (
        <div className="checkOutProfile-container">
            <div className="checkOutProfile-container-main">
                <div 
                    className="checkOutProfile-container-main-volver"
                    onClick={() => window.history.back()}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
                    <b className="checkOutProfile-container-main-volver-text bold medium">Volver</b>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold" style={{ marginBottom: 0 }}>Tus datos</h6>
                <div className="checkOutProfile-container-main-loginContainer">
                    <p className="checkOutProfile-container-main-loginContainer-text">¿Ya tenés cuenta?&nbsp;</p>
                    <p className="checkOutProfile-container-main-loginContainer-click bold" onClick={() => props.setLoggingIn(true)}>Iniciá sesión</p>
                </div>
                <div className="checkOutProfile-container-main-list">
                    <CustomTextInput
                        label='Nombre'
                        required
                        changeText={(e: string) => setNombre(e)}
                        value={nombre}
                        error={!isValidName(nombre)}
                        success={nombre !== '' ? isValidName(nombre) : false}
                        shake={(!isValidName(nombre) || nombre === '') && disableShake}
                    />
                    <CustomTextInput
                        label='Apellido'
                        required
                        changeText={(e: string) => setApellido(e)}
                        value={apellido}
                        error={!isValidName(apellido)}
                        success={apellido !== '' ? isValidName(apellido) : false}
                        shake={(!isValidName(apellido) || apellido === '') && disableShake}
                    />
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        changeText={(e: string) => setFechaNac(e)}
                        value={fechaNac}
                        error={fechaNac?.length === 0 ? false : !isValidDate(fechaNac)}
                        success={isValidDate(fechaNac)}
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                        type="date"
                        max='2999-12-31'
                        shake={(!isValidDate(fechaNac) || fechaNac?.length === 0) && disableShake}
                        onKeyDown={(event: any) => {
                            if (event?.key === 'Tab' || event?.keyCode === 9) {
                                event.preventDefault();
                                setTipoDocuFocus(true)
                            }
                        }}
                    />
                    <div className={!tipoDocu && disableShake ? "checkOutProfile-container-main-list-item-selectorWrapper shake-animation" : "checkOutProfile-container-main-list-item-selectorWrapper"} style={{ opacity: !tipoDocuList ? 0.5 : 1 }}>
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            onValueSelected={(a: any) => setTipoDocu(a.codigo)}
                            selectedItem={tipoDocuList && tipoDocu ? tipoDocuList?.filter((a: any) => a.codigo == tipoDocu)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de documento'
                            deleteSelectedItem={() => {
                                setNumDocu('')
                                setTipoDocu(null)
                            }}
                            disabled={!tipoDocuList}
                            noSearch
                            focus={tipoDocuFocus}
                            shake={!tipoDocu && disableShake}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Nro de documento'
                        required
                        changeText={(e: string) => setNumDocu(e.replace(/[^0-9]+/g, ''))}
                        value={numDocu}
                        success={validDocument(numDocu, tipoDocu)}
                        placeholder='Ingresá sólo números sin puntos'
                        error={!validDocument(numDocu, tipoDocu) && numDocu?.length !== 0}
                        maxLength={tipoDocu === 8 || tipoDocu === 9 ? 11 : 8}
                        disabled={!tipoDocu}
                        shake={!validDocument(numDocu, tipoDocu) && disableShake}
                        onKeyDown={(event: any) => {
                            if (event?.key === 'Tab' || event?.keyCode === 9) {
                                event.preventDefault();
                                setEstadoCivilFocus(true)
                            }
                        }}
                    />
                    <div className={(!sexo && disableShake) ? "checkOutProfile-container-main-list-item-optionWrapper shake-animation" : "checkOutProfile-container-main-list-item-optionWrapper"} style={{ opacity: !sexoList ? 0.5 : 1 }}>
                        <p className="checkOutProfile-container-main-list-item-optionWrapper-title bold">Sexo que figura en el DNI&nbsp;<ul>*</ul></p>
                        <div className="checkOutProfile-container-main-list-item-optionWrapper-list" style={{ opacity: !sexoList ? 0.5 : 1 }}>
                            {sexoList?.map((a: any, index: any) => (
                                <div className="checkOutProfile-container-main-list-item-optionWrapper-list-item" key={index}>
                                    <div 
                                        className="checkOutProfile-container-main-list-item-optionWrapper-list-item-circle" 
                                        style={{ border: (!sexo && disableShake) ? '2px solid var(--statusErrorPrimary)' : '2px solid var(--secondary500)' }}
                                        onClick={() => setSexo(a.codigo)}
                                    >
                                        <div 
                                            className="checkOutProfile-container-main-list-item-optionWrapper-list-item-circle-inner" 
                                            style={{ transform: a.codigo === sexo ? `scale(${1})` : `scale(${0})` }}
                                        />
                                    </div>
                                    <p className="checkOutProfile-container-main-list-item-optionWrapper-list-item-text bold">{a.codigo}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={!estadoCivil && disableShake ? "checkOutProfile-container-main-list-item-selectorWrapper shake-animation" : "checkOutProfile-container-main-list-item-selectorWrapper"} style={{ opacity: !estadoCivilList ? 0.5 : 1 }}>
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Estado Civil' 
                            list={estadoCivilList}
                            onValueSelected={(a: any) => setEstadoCivil(a.codigo)}
                            selectedItem={estadoCivilList && estadoCivil ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivil)[0]?.descripcion : ''} 
                            placeholder='Seleccioná tu estado civil' 
                            deleteSelectedItem={() => setEstadoCivil(null)}
                            disabled={!estadoCivilList}
                            noSearch
                            focus={estadoCivilFocus}
                            shake={!estadoCivil && disableShake}
                        />
                    </div>
                    <div className={!nacionalidad && disableShake ? "checkOutProfile-container-main-list-item-selectorWrapper shake-animation" : "checkOutProfile-container-main-list-item-selectorWrapper"} style={{ opacity: !nacionalidadList ? 0.5 : 1 }}>
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Nacionalidad'
                            list={nacionalidadList}
                            onValueSelected={(a: any) => setNacionalidad(a.codigo)}
                            selectedItem={nacionalidadList && nacionalidad ? nacionalidadList?.filter((a: any) => a.codigo == nacionalidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu nacionalidad'
                            deleteSelectedItem={() => setNacionalidad(null)}
                            disabled={!nacionalidadList}
                            shake={!nacionalidad && disableShake}
                        /> 
                    </div>
                    <div className={!profesion && disableShake ? "checkOutProfile-container-main-list-item-selectorWrapper shake-animation" : "checkOutProfile-container-main-list-item-selectorWrapper"} style={{ opacity: !profesionList ? 0.5 : 1 }}>
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Profesión&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Profesión'
                            list={profesionList}
                            onValueSelected={(a: any) => setProfesion(a.codigo)}
                            selectedItem={profesionList && profesion ? profesionList?.filter((a: any) => a.codigo == profesion)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu profesión'
                            deleteSelectedItem={() => setProfesion(null)}
                            disabled={!profesionList}
                            shake={!profesion && disableShake}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-customInput" style={{opacity: quoteData ? 0.6 : 1}}>
                        <div className="checkOutProfile-container-main-list-item-customInput-label">
                            <p className="checkOutProfile-container-main-list-item-customInput-label-text bold">Teléfono celular</p>
                            <p className="checkOutProfile-container-main-list-item-customInput-label-requiered bold">&nbsp;*</p>
                        </div>
                        <div className="checkOutProfile-container-main-list-item-customInput-inputContainer">
                            <div className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper" style={{ width: '25%' }}>
                                <b className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-prefix regular large">0</b>
                                <input
                                    className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input"
                                    maxLength={5}
                                    onChange={(e) => setCodigoArea(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                    value={codigoArea}
                                    disabled={quoteData}
                                />
                                {codigoArea?.length >= 1 && <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span >}
                            </div>
                            <div className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper" style={{ width: '65%' }}>
                                <b className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-prefix regular large">15</b>
                                <input
                                    className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input"
                                    maxLength={8}
                                    onChange={(e) => setCelular(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                    value={celular}
                                    disabled={quoteData}
                                />
                                {celular?.length > 0 && celular?.length < 6 ? 
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                    : celular?.length >= 6 ?
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        changeText={(e: string) => setEmail(e)}
                        success={validateEmail(email)}
                        error={email?.length > 0 ? !validateEmail(email) : false}
                        errorMessage='E-mail inválido'
                        placeholder='nombre@ejemplo.com'
                        shake={(email?.length === 0 || !validateEmail(email)) && disableShake}
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
            </div>
            <div className="checkOutProfile-container-endWrapper">
                <div className="checkOutProfile-container-endWrapper-main">
                    <DiscountDetailsComponent/>
                    <MainButton
                        id='Continuar Boton' 
                        fill
                        color='var(--secondary500)'
                        //disabled={isDisabled()}
                        text='CONTINUAR'
                        onButtonPressed={() => {
                            if (isDisabled()) setDisableShake(true)
                            else {
                                const dateGoodFormatUser = Moment(fechaNac, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                const userData = {
                                    'name': nombre,
                                    'lastName': apellido,
                                    'birthDate': dateGoodFormatUser,
                        
                                    'documentId': tipoDocu,
                                    'documentNumber': numDocu,
                                    'sexId': sexo,
                        
                                    'civilStatusId': estadoCivil,
                                    'nacionalityId': nacionalidad,
                                    'profesion': profesion,
                                    
                                    'phone': celular,
                                    'phonePrefix': codigoArea,
                                    'userName': email
                                }
                                if(props.userAccessToken && admin) {
                                    props.editCreateUserFinaer(userData, props.checkOutData?.id, admin, appBaseIframe, currentPackage);
                                } else {
                                    props.createUserFinaer(userData, props.checkOutData?.id, admin, appBaseIframe, currentPackage);
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    checkOutData: state.checkOut.checkOutData,
    userAccessToken: state.general.userAccessToken,
    userData: state.user.userData
  }; 
};

export default connect(mapStateToProps, {
    createUserFinaer,
    editCreateUserFinaer,
    getUserDataLists
})(CheckOutProfile);
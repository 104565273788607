import React, { useEffect, useState } from 'react'
import './CheckOutProduct.css'
import { connect, useDispatch } from 'react-redux';
import {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct,
  actionEditUserByIdIfNecessary,
  actionAutoAsignAdminId,
  checkOutProductNoRefresh
} from '../../../actions'
import MainButton from '../../generalComponents/MainButton/MainButton';
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import GetGlobalForm from '../../generalComponents/GlobalForms/getGlobalForm';

const CheckOutProduct = (props: any) => {

  const [shake, setShake] = useState<boolean>(false);

  useEffect(() => {
    if(shake) {
      setTimeout(() => {
        setShake(false)
      }, 1000)
    }
  }, [shake])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  const handleContinuarPress = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const localFinalAnswersAux = {...props.localFinalAnswers}
    Object.assign(localFinalAnswersAux, props.localAnswers);
    props.setLocalFinalAnswers(localFinalAnswersAux)
    if(props.productRequirements[props.productRequirements?.length -1]?.order > props.currentProductRequirements?.order) {
      if(props.admin && props.productRequirements[props.currentProductRequirementsIndex + 1]?.id?.includes("inmobiliaria")) {
        actionAutoAsignAdminId(props, localFinalAnswersAux)
      } else props.checkOutProductNoRefresh(props.checkOutData.id, localFinalAnswersAux)
      props.setCurrentProductRequirements(props.productRequirements[props.currentProductRequirementsIndex + 1])
      props.setCurrentProductRequirementsIndex(props.currentProductRequirementsIndex + 1)
      props.setCurrentProductRequirementStep(props.productRequirements[props.currentProductRequirementsIndex + 1]?.order)
      props.setCurrentProductRequirementStepTitle(props.productRequirements[props.currentProductRequirementsIndex+ 1]?.display?.name)
      props.setLocalAnswers(null);
      props.setDisableContinuar(true);
      if(props.currentProductRequirements?.input?.type === "geoposition") {
        updateUserData();
      }
    } else { //ultimo paso
      props.checkOutProduct(props.checkOutData.id, localFinalAnswersAux)
      props.setRefPayment(true)
    }
  }

  const updateUserData = () => {
    const keys = Object.keys(props.localAnswers);
    const randomIndex = Math.floor(Math.random() * keys.length);
    const randomKey = keys[randomIndex];
    const randomValue = props.localAnswers[randomKey];
    const userData: any = JSON.parse(randomValue);
    const data = {
      'addressPostalCode': userData?.codigoPostalVivienda,
      'addressStreet': userData?.calleVivienda,
      'addressNumber': userData?.nroVivienda,
      'addressFloor': userData?.pisoVivienda === '' ? null : userData?.pisoVivienda,
      'dpto': userData?.dptoVivienda === '' ? null : userData?.dptoVivienda,
    }
    actionEditUserByIdIfNecessary(props.checkOutData?.policyHolder?.id, data)
  }

  const handleVolver = () => {
    if(props.currentProductRequirementsIndex === 0) {
      props.goBackToUserDetails()
    } else {
      props.setCurrentProductRequirements(props.productRequirements[props.currentProductRequirementsIndex - 1])
      props.setCurrentProductRequirementsIndex(props.currentProductRequirementsIndex - 1)
      props.setCurrentProductRequirementStep(props.productRequirements[props.currentProductRequirementsIndex - 1]?.order)
      props.setCurrentProductRequirementStepTitle(props.productRequirements[props.currentProductRequirementsIndex - 1]?.display?.name)
    }
  }

  const renderForm = () => {
    return (
      <div className="checkOutProduct-container">
        <div className="checkOutProduct-container-main">
          <div 
            className="checkOutProduct-container-main-volver"
            onClick={() => handleVolver()}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
            <b className="checkOutProduct-container-main-volver-text bold medium">Volver</b>
          </div>
          <div className="checkOutProduct-container-main-form">
            <GetGlobalForm
              parent='CheckOut'
              item={props.currentProductRequirements}
              currentAnswer={props.localAnswers} 
              setLocalAnswers={props.setLocalAnswers} 
              next={() => {}} 
              setCurrentAnswer={null}
              checkOutData={props.checkOutData}
              setDisableContinuar={props.setDisableContinuar}
              shake={shake}
            />
          </div>
        </div>
        <div className="checkOutProduct-container-endWrapper">
          <div className="checkOutProduct-container-endWrapper-main">
            <DiscountDetailsComponent />
            <div className="checkOutProduct-container-endWrapper-main-detailsWrapper-buttonWrapper">
              <MainButton
                id='Continuar Boton'
                fill
                color='var(--secondary500)'
                //disabled={props.disableContinuar}
                text='CONTINUAR'
                onButtonPressed={() => {
                  if(props.disableContinuar) setShake(true)
                  else handleContinuarPress()
                }}
                altStyle10
              />
            </div>
          </div>
        </div>
      </div>
    )
  };

  if (props.productRequirements === undefined) {
    return <p>Cargando...</p>
  }

  return renderForm();
}

const mapStateToProps = (state: any) => {
  return {
    checkOutData: state.checkOut.checkOutData,
  };
};

export default connect(mapStateToProps, {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct,
  checkOutProductNoRefresh
})(CheckOutProduct);
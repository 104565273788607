import { useState } from 'react';
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldDate.css';
import Moment from 'moment/moment'
const FormFieldDate = (props: any) => {

  const [localText, setLocalText] = useState<string>('')

  const handleSendData = (res: any) => {
    const value = Moment(res, 'YYYY-MM-DD').utcOffset('+0000').startOf('day').toISOString();
    if(props.parent === 'Cotizar') {
      const data = {
        "questionId": props.data?.id,
        "value": value,
      }
      props.onUpdate(data)
    } else {
      props.onUpdate(value)
    }
  }
  
  return (
    <div 
      className="customDateInput-container"
      id={props.data.display.name}
    >
      <CustomTextInput
        maxLength={props.data?.input?.config?.max}
        label={props.data?.display?.name}
        required={props.data?.required}
        placeholder={props.data?.display?.placeholder}
        value={localText}
        date
        changeText={(e: string) => {
          setLocalText(e)
          handleSendData(e)
        }}
      />
    </div>
  )
}

export default FormFieldDate;
import React from 'react'
import './CategoriaButton.css'

const CategoriaButton = (props: any) => {

  const list = [
    {
     categoryId: 54,
     list: [
      {
        name: 'Incendio edificio',
        check: true
      },
      {
        name: 'Resp. civil linderos',
        check: true
      }
     ] 
    },
    {
      categoryId: 55,
      list: [
       {
         name: 'Incendio edificio',
         check: true
       },
       {
        name: 'Incendio contenido',
        check: true
        },
        {
          name: 'Cristales',
          check: true
        },
        {
          name: 'Resp. civil hechos privados',
          check: true
        },
       {
         name: 'Resp. civil linderos',
         check: true
       },
       {
        name: 'Daños por agua e inundación',
        check: true
      },
      {
        name: 'Robo bienes de inquilinos',
        check: true
      },
       {
         name: 'Urgencia domiciliaria',
         check: true
       },
       {
         name: 'Asistencia al viajero',
         check: true
       },
      ] 
     }
  ]

  const aditionalData = () => {
    return props.categoryId === 54 || props.categoryId === 55;
  }
 
  const getProductName = (name: string) => {
    if(name === "HOGAR -INCENDIO-") return "HOGAR<br/>-INCENDIO-";
    else if(name === "HOGAR -MULTICOBERTURA-") return "HOGAR<br/>-MULTICOBERTURA-";
    return name;
  }

  return (
    <div className="categoriaButton-container">
      <div 
        className="categoriaButton-container-box"
        onClick={() => {if(!props.commingSoon) props.categoryPressed()}}
        id={props.id}
      > 
        <img className="categoriaButton-container-box-image" src={props.image} alt=""/>
        <h6 className='categoriaButton-container-box-text bold' dangerouslySetInnerHTML={{__html: getProductName(props.name.toUpperCase())}}/>
        <img className="categoriaButton-container-box-backGroundImage" src={props.backImage} alt=""/>
      </div>
      {aditionalData() &&
        <div className="categoriaButton-container-aditionalData">
          {list?.filter((a: any) => a.categoryId === props.categoryId)[0]?.list?.map((a: any, index: any) => (
            <div className="categoriaButton-container-aditionalData-item">
              {a.check ? 
                <span className="material-symbols-outlined" style={{ fontSize: 20, color: 'var(--statusSuccessPrimary)' }}>check_circle</span>
                :
                <span className="material-symbols-outlined" style={{ fontSize: 20, color: 'var(--statusErrorPrimary)' }}>cancel</span>
              }
              <b className="categoriaButton-container-aditionalData-item-name regular medium">{a.name}</b>
            </div>
          ))}
        </div>
      }
    </div>
  );

} 
 
export default CategoriaButton;
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import './Checkout.css'
import Header from '../../components/generalComponents/header/Header';
import {
  checkOutOfferById,
  loginUser,
  registerUser,
  getUserAuth,
  checkOutModifyStep,
  userAccessTokenSet,
  setAccessTokenAux
} from '../../actions'
import { useMediaQuery } from 'react-responsive'
import CheckOutProfile from '../../components/checkoutComponents/CheckOutProfile/CheckOutProfile';
import CheckOutPayment from '../../components/checkoutComponents/CheckOutPayment/CheckOutPayment';
import CheckOutProduct from '../../components/checkoutComponents/CheckOutProduct/CheckOutProduct';
import Cookies from 'universal-cookie';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import { LOADING } from '../../actions/types';
import { ProductRequirement, getCheckOutRequerements } from '../../services/CheckoutService';
import { genericErrorHandler } from '../../util/GenericErrorHandler';
import CheckOutLogin from '../../components/checkoutComponents/CheckOutLogin/CheckOutLogin';

let isTabletOrMobile = false;
const Checkout = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const [currentStepString, setCurrentStepString] = useState<any>(null)
  const [checkoutId, setCheckoutId] = useState<string>();
  const [currentProductRequirementStepTitle, setCurrentProductRequirementStepTitle] = useState<string>('');
  const [currentProductRequirementStep, setCurrentProductRequirementStep] = useState<number>(-1);

  //PRODUCT REQUIREMENT
  const [localAnswers, setLocalAnswers] = useState<any>(null);
  const [localFinalAnswers, setLocalFinalAnswers] = useState<any>({});

  const [disableContinuar, setDisableContinuar] = useState<boolean>(true);
  const [currentProductRequirements, setCurrentProductRequirements] = useState<any>(null);
  const [currentProductRequirementsIndex, setCurrentProductRequirementsIndex] = useState<any>(null);

  //OTTHER
  const [admin, setAdmin] = useState<boolean>(false)
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [productRequirements, setProductRequirements] = useState<ProductRequirement[]>();
  const [quoteData, setQuoteData] = useState<any>(null);

  useEffect(() => {
    handleUrlParams()
  }, [])

  useEffect(() => {
    if(productRequirements) {
      setCurrentProductRequirements(productRequirements[0])
      setCurrentProductRequirementsIndex(0)
      setCurrentProductRequirementStep(productRequirements[0]?.order)
      setCurrentProductRequirementStepTitle(productRequirements[0]?.display?.name)
    }
  }, [productRequirements])

  useEffect(() => {
    async function fetchData() {
      if (props.checkOutData?.id) {
        try {
          dispatch({ type: LOADING, payload: true });
          const checkOutRequerements = await getCheckOutRequerements(props.checkOutData.id);
          setProductRequirements(checkOutRequerements);
        } catch (error) {
          genericErrorHandler(error);
        } finally {
          dispatch({ type: LOADING, payload: false });
        }
      }
    }
    fetchData()
  }, [props.checkOutData?.id])

  useEffect(() => {
    if (props.checkOutData) {
      const filteredSteps = props.checkOutData.steps.filter(a => a.status === "PENDING")
      if (filteredSteps) {
        setCurrentStepString(filteredSteps[0])
        //setCurrentStepString({type: 'customerProduct'}) //FOR TESTING
      }
    }
  }, [props.checkOutData])

  useEffect(() => {
    scrollToTop()
  }, [currentStepString])

  const handleUrlParams = () => {
    let cookiesUA = new Cookies();
    cookiesUA = cookiesUA.get('userAccessToken')
    let checkoutId: any = searchParams.get("checkoutId")
    let admin: any = searchParams.get("admin")
    if (admin) {
      setAdmin(true)
    }
    if (cookiesUA) {
      props.userAccessTokenSet(cookiesUA)
      if (props.userData?.emailValidated) {
        props.getUserAuth();
      }
    }
    if (props.step === '') {
      if (checkoutId) {
        props.checkOutOfferById(checkoutId)
        setCheckoutId(checkoutId);
      }
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const goBackToUserDetails = () => {
    setCurrentStepString({type: 'personalInfo'})
  }

  const goBackToProductDetails = () => {
    setCurrentStepString({type: 'customerProduct'})
  }

  const renderCheckOutStep = () => {
    if(loggingIn) {
      return (
        <CheckOutLogin
          checkOutData={props.checkOutData}
          navigate={navigate}
          setLoggingIn={setLoggingIn}
          quoteData={quoteData}
          loginUser={(emailLogin: any, contraseñaLogin: any) => props.loginUser(emailLogin, contraseñaLogin, false, '', props.checkOutData?.id, navigate, setLoggingIn)}
        />
      )
    } else {
      switch (currentStepString?.type) {
        case "personalInfo":
          return (
            <CheckOutProfile
              checkOutData={props.checkOutData}
              navigate={navigate}
              setLoggingIn={setLoggingIn}
              setQuoteData={setQuoteData}
            />
          )
        case "customerProduct":
            return (
              <CheckOutProduct
                goBackToUserDetails={() => goBackToUserDetails()}
                checkOutData={props.checkOutData}
                promoCodeError={props.promoCodeError}
                isTabletOrMobile={isTabletOrMobile}
                productRequirements={productRequirements}
                setCurrentProductRequirementStepTitle={setCurrentProductRequirementStepTitle}
                setCurrentProductRequirementStep={setCurrentProductRequirementStep}
                localAnswers={localAnswers}
                setLocalAnswers={setLocalAnswers}
                localFinalAnswers={localFinalAnswers}
                setLocalFinalAnswers={setLocalFinalAnswers}
                disableContinuar={disableContinuar}
                setDisableContinuar={setDisableContinuar}
                currentProductRequirements={currentProductRequirements}
                setCurrentProductRequirements={setCurrentProductRequirements}
                currentProductRequirementsIndex={currentProductRequirementsIndex}
                setCurrentProductRequirementsIndex={setCurrentProductRequirementsIndex}
                admin={admin}
              />
            )
        case "paymentInfo":
          return (
            <CheckOutPayment 
              goBackToProductDetails={() => goBackToProductDetails()}
              admin={admin}
            />
          )
        default:
          return null;
      }
    }
  }

  const getTotalSteps = () => {
    return (productRequirements?.length + props.checkOutData?.steps?.length -1)
  }

  const renderCheckOutTitle = () => {
    switch (currentStepString?.type) {
      case "personalInfo":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO 1 DE {getTotalSteps()}</b>
            <h4 className="checkout-container–topContainer-left-titleText bold" >Datos personales</h4>
          </div>
        )
      case "customerProduct":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO {currentProductRequirementStep + 1} DE {getTotalSteps()}</b>
            <h4 className="checkout-container–topContainer-left-titleText bold">{currentProductRequirementStepTitle}</h4>
          </div>
        )
      case "paymentInfo":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO {getTotalSteps()} DE {getTotalSteps()}</b>
            <h4 className="checkout-container–topContainer-left-titleText bold">Medio de pago</h4>
          </div>
        )
      default:
        return null;
    }
  }

  return (
    <div className="checkout-container">
      {!admin && <Header
        logout={props.userAccessToken}
        logoutPresse={() => {
          props.userAccessTokenSet(null);
          dispatch(setAccessTokenAux(null));
          dispatch({ type: 'USER_DATA', payload: null });
          navigate(GetCountryUrl(props.countryId) + 'inicio')
        }}
      />}
      <div className="checkout-container–topContainer"> {/* Titulo y pasos */}
        {renderCheckOutTitle()}
      </div>
      <div className="checkout-container–main"> {/* Componente del paso */}
        {renderCheckOutStep()}
      </div>
      {props.checkOutConfirmStart && <div className="checkout-container-blockScreen"></div>}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
    userData: state.user.userData,
    promoCodeError: state.checkOut.promoCodeError,
    userAccessToken: state.general.userAccessToken,
    step: state.checkOut.step,
    checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
  };
};

export default connect(mapStateToProps, {
  checkOutOfferById,
  loginUser,
  registerUser,
  getUserAuth,
  checkOutModifyStep,
  userAccessTokenSet
})(Checkout);
import React, {useEffect, useState, useRef} from 'react'
import './AccordionCotizar.css'
import { ReactComponent as ArrowQuote } from '../../assets/images/svgs/arrowQuote.svg';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const AccordionCotizar = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

    const [open, setOpen] = useState<Boolean>(false)

    const mainRef: any = useRef()

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                setTimeout(() => {
                    setOpen(false)
                }, 100)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    return ( 
        <div 
            id={props.id}
            ref={mainRef}
            className="accordionCotizar-container" 
            onClick={() => setOpen(!open)}
        >
            <div className="accordionCotizar-container-index">
                {isTabletOrMobile ?
                    <h6 className="accordionCotizar-container-index-title regular">{props.title}</h6>
                    :
                    <p className="accordionCotizar-container-index-title regular">{props.title}</p>
                }
                <div style={{transform: open ? `rotate(${180}deg)` : `rotate(${0}deg)`}}>
                    <ArrowQuote/>
                </div>
            </div>
            <div className={open ? "accordionCotizar-container-detailOpen" : "accordionCotizar-container-detailClose"}>
                <div className="accordionCotizar-container-detail-line"></div>
                {isTabletOrMobile ?
                    <p onClick={() => props.onClick ? props.onClick(): {}} className="accordionCotizar-container-detail-text regular" dangerouslySetInnerHTML={{__html: props.text}}/>
                    :
                    <b onClick={() => props.onClick ? props.onClick(): {}} className="accordionCotizar-container-detail-text large regular" dangerouslySetInnerHTML={{__html: props.text}}/>
                }
            </div>
        </div>
    );
}

export default AccordionCotizar;
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom'
import './CheckoutFinAdmin.css'
import success from '../../assets/lotties/success.json'
import error from '../../assets/lotties/errorPago.json'
import Lottie from 'react-lottie';
import logo from '../../assets/images/svgs/logoColor.svg'
import successWaveDesktop from '../../assets/images/svgs/adminSuccessDesktop.svg'
import errorWaveDesktop from '../../assets/images/svgs/adminErrorDesktop.svg'
import successWaveMobile from '../../assets/images/svgs/adminSuccessMobile.svg'
import errorWaveMobile from '../../assets/images/svgs/adminErrorMobile.svg'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useMediaQuery } from 'react-responsive';
import { getPolicyInfo } from '../../actions';

let isTabletOrMobile = false;
const CheckoutFinAdmin = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const [estado, setEstado] = useState<any>(null)
  const [searchParams] = useSearchParams();
  const [productId, setProductId] = useState<any>(null);
  const [productData, setProductData] = useState<any>(null);
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);

  useEffect(() => {
    handleUrlParams()
  }, [])

  useEffect(() => {
    getPolicyInfo(productId, setProductData)
  }, [productId])

  const handleUrlParams = () => {
    const estado = searchParams.get("estado");
    const id = searchParams.get('id');
    setEstado(estado)
    if (estado === 'Exito') setProductId(id);
  }

  const estadoColor = () => {
    if(estado === 'Exito') return 'var(--statusSuccessPrimary)'
    return 'var(--statusErrorPrimary)'
  }

  const lottieSize = estado === 'Exito' ? 120 : 105

  const extractErrorMessage = (message, keyword) => {
    const startIndex = message.indexOf(keyword);
    if (startIndex !== -1) {
      return message.substring(startIndex + keyword.length).trim();
    }
    return "Error";
  };

  return (
    <div className="checkoutFinAdmin-container">
      <div className="checkoutFinAdmin-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: estado === 'Exito' ? success : error,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={lottieSize}
          width={lottieSize}
          isStopped={false}
          isPaused={false}
        />
        <img src={logo} className="checkoutFinAdmin-container-main-img"/>
        <h5 className="checkoutFinAdmin-container-main-title light">{estado === 'Exito' ? '¡Felicidades!' : '¡Algo salió mal!'}</h5>
        <h5 className="checkoutFinAdmin-container-main-subTitle regular">{estado === 'Exito' ? 'El nro de póliza es ' + productData?.number : 'No pudimos completar el proceso de la cobertura'}</h5>
        <b className="checkoutFinAdmin-container-main-description regular large">{estado === 'Exito' ? 'En breve le enviaremos un mail al asegurado con su póliza y los detalles de su cobertura.' : 'El motivo es: ' + extractErrorMessage(props.checkOutConfirmErrorMessage, 'Error al emitir:') + ' Lamentamos este inconveniente, este error es poco habitual. Por favor, reiniciá el proceso y probá de nuevo.'}</b>
        {estado === 'Exito' &&
          <div className="checkoutFinAdmin-container-main-bot">
            <MainButton
              id='DESCARGAR PÓLIZA'
              fill
              color='var(--secondary500)'
              disabled={!productData?.url}
              text='DESCARGAR PÓLIZA'
              onButtonPressed={() => window.open(productData?.url)}
            />
          </div>
        }
        <div className="checkoutFinAdmin-container-main-bot">
          <MainButton
            id='VOLVER A COBERTURAS'
            fill
            color='var(--secondary500)'
            text='VOLVER A COBERTURAS'
            onButtonPressed={() => {
              if(window.location.hostname == 'localhost') {
                window.parent.postMessage('/insurance_policies', 'http://localhost:3000')
              } else {
                window.parent.postMessage('/insurance_policies', appBaseIframe)
              }
            }}
          />
        </div>
        <div className="checkoutFinAdmin-container-main-bot">
          <MainButton
            id='VER EL DASHBOARD'
            text='VER EL DASHBOARD'
            border
            color='var(--secondary500)'
            onButtonPressed={() => {
              if(window.location.hostname == 'localhost') {
                window.parent.postMessage('/dashboard', 'http://localhost:3000')
              } else {
                window.parent.postMessage('/dashboard', appBaseIframe)
              }
            }}            
          />
        </div>
      </div>
      <div className="checkoutFinAdmin-container-wave">
        {isTabletOrMobile ? 
          <img src={estado === 'Exito' ? successWaveMobile : errorWaveMobile} className="checkoutFinAdmin-container-wave-img"/>
          :
          <img src={estado === 'Exito' ? successWaveDesktop : errorWaveDesktop} className="checkoutFinAdmin-container-wave-img"/>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    checkOutConfirmErrorMessage: state.checkOut.checkOutConfirmErrorMessage,
  };
};

export default connect(mapStateToProps, {})(CheckoutFinAdmin);
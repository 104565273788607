import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';

import Facebook from "./assets/logo/Facebook.svg";
import FacebookC from "./assets/logo/FacebookC.svg";
import FacebookImg from "./assets/images/facebookC.png";

import Instagram from "./assets/logo/Instagram.svg";
import InstagramC from "./assets/logo/InstagramC.svg";
import InstagramImg from "./assets/images/instagram.png";

import Whatsapp from "./assets/logo/Whatsapp.svg";
import WhatsappC from "./assets/logo/WhatsappC.svg";
import WhatsappImg from "./assets/images/instagram.png";

export const countries = [
  {
    title: "ARGENTINA",
    id: 1,
    img: Argentina,
    url: '/',
    lang: () => import('./assets/lang/es-AR-LaSegunda.json'),
    htmlMetaInfo: {
      title: 'Confiá el bienestar de tu hogar a Finaer'
    },
  }
];

export const categorias = null;

export const burgerMenuList = (props: any, navigate: any, path: any, setAtencionAlert: any) => [
  {
    id: 1,
    label: 'HOME',
    icon: 'home',
    action: () => {
      props.setBurgerOpen(false)
      if (props.userAccessToken) {
        navigate('/home')
      } else {
        navigate('/inicio')
      }
    },
    selected: props.userAccessToken ? path === '/home' : path === '/inicio'
  },
  {
    id: 2,
    label: 'COTIZÁ TU COBERTURA',
    icon: 'verified_user',
    action: () => {
      props.setBurgerOpen(false)
      if (props.userAccessToken) {
        navigate('/home')
      } else {
        navigate('/inicio')
      }
      props.setRefCategory(true)
    }
  },
  {
    id: 4,
    label: 'CONTACTANOS',
    icon: 'contact_support',
    action: () => {
      props.setBurgerOpen(false)
      navigate('/contacto')
    },
    selected: path === '/contacto',
  }
]

export const socialLinks = [
  {
    svg: Facebook,
    svgC: FacebookC,
    img: FacebookImg,
    link: "https://www.facebook.com/FinaerSa/",
  },
  {
    svg: Instagram,
    svgC: InstagramC,
    img: InstagramImg,
    link: "https://www.instagram.com/garantias_finaer/",
  },
  {
    svg: Whatsapp,
    svgC: WhatsappC,
    img: WhatsappImg,
    link: "https://api.whatsapp.com/send?phone=541168425100&text=%C2%A1Hola!%F0%9F%91%8BQuer%C3%ADa%20consultar%20sobre...",
  }
];
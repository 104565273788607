import React from "react";
import { useNavigate } from "react-router-dom";
import PBOLogo from "../../../assets/images/svgs/logoBlanco.svg";
import { socialLinks } from "../../../data";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";
import { GetCountryUrl } from "../../../util/GetCountryUrl";
import { GetGlobalParams } from "../../../util/GetGlobalParams";
import { setRefCategory, setRefQuestion } from "../../../actions";
import { connect } from "react-redux";
import logo_superintendencia from '../../../assets/images/svgs/logo_superintendencia.svg'
import { ReactSVG } from "react-svg";

let isTabletOrMobile = false;
const Footer = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const navigate = useNavigate();

  return (
    <div className="Footer-container">
      <div className="Footer-container-wrap">
        <div className="Footer-container-wrap-main">
          <div className="Footer-container-wrap-content">
            <div className="Footer-container-wrap-content-links">
              <img src={PBOLogo} alt="pbo logo" className="Footer-container-wrap-content-links-logo" onClick={() => navigate("/")}/>
              <div className="Footer-container-wrap-content-links-list">
                {socialLinks?.map((redSocial: any, index: any) => (
                  <a key={index} href={redSocial.link} target="_blank" rel="noreferrer">
                    <ReactSVG
                      src={redSocial.svg}
                      style={{ width: 36, height: 36, cursor: 'pointer', paddingRight: 10 }}
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Menú</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/")}>Home</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefCategory(true)
                }}
              >Cotizá tu cobertura</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/contacto")}>Contactanos</b>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Canales de atención</b>
              <b className="Footer-container-wrap-content-section-info regular large">0341-4201000</b>
              <b className="Footer-container-wrap-content-section-info regular large">Lunes a  Jueves de 08:00 a 17:30 h</b>
              <b className="Footer-container-wrap-content-section-info regular large">Viernes de 08:30 a 15.30 h</b>
            </div>
          </div>
          <div className="Footer-container-wrap-info">
            <div className="Footer-container-wrap-info-rights">
              {isTabletOrMobile ?
                <b className="Footer-container-wrap-info-rights-law regular medium">Finaer © {new Date().getFullYear()} - Todos los derechos reservados</b>
                :
                <b className="Footer-container-wrap-info-rights-law regular small">Finaer © {new Date().getFullYear()} - Todos los derechos reservados</b>
              }
              <div className="Footer-container-wrap-info-rights-power">
                {isTabletOrMobile ?
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular medium">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular medium" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                  :
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular small">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular small" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-container-legals">
        <div className="Footer-container-legals-wrap">
          <div className="Footer-container-legals-data">
            <div className="Footer-container-legals-data-first">
              <div className="Footer-container-legals-data-first-noColorCont">
                <p className="Footer-container-legals-data-first-noColorCont-noColor">N° de inscripción SSN<br/>0317 - 0618 - 0117 - 0436</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-noColorCont-noColor">Departamento de Orientación y<br/>Asistencia al Asegurado - DOAA</p>
              </div>
              <div className="Footer-container-legals-data-first-colorCont">
                <p className="Footer-container-legals-data-first-colorCont-color">0800-666-8400</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-colorCont-color">www.argentina.gob.ar/ssn</p>
              </div>
            </div>
            <div className="Footer-container-legals-data-superImg">
              <img className="Footer-container-legals-data-superImg-img" src={logo_superintendencia}/>
            </div>
          </div>
          <div className="Footer-container-legals-publicidad">
            <p className="Footer-container-legals-publicidad-textOne">Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 0317 - 0618 - 0117 - 0436, de conformidad con la Resolución SSN ° 38052 del 20/12/2013: Sistema Finaer SA. es una sociedad anónima según la ley argentina. Los seguros son emitidos por las compañías aseguradoras. Consulte compañía aseguradoras de acuerdo al producto solicitado. Superintendencia de Seguros de Nación teléfono 0800-666-8400 y su página web www.argentina.gob.ar/ssnseguros son emitidos por las compañías aseguradoras. Consulte compañía aseguradoras de acuerdo al producto solicitado. Superintendencia de Seguros de Nación teléfono 0800-666-8400 y su página web www.argentina.gob.ar/ssn</p>
            <p className="Footer-container-legals-publicidad-textTwo">El Servicio de Atención al Asegurado está integrado por:<br/>RESPONSABLE: Bazillo, Nahuel<br/>SUPLENTE: Dasso, Mariano</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
      countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  setRefCategory,
  setRefQuestion
})(Footer);
import { AxiosResponse } from "axios";
import { useState, forwardRef, useImperativeHandle } from "react"
import { saveCbuCheckout } from "../../actions/CheckOutActions";
import { PaymentMethodComponentProps, PaymentMethodComponentRef } from "../PaymentMethodComponent";
import { genericErrorHandler } from "../../util/GenericErrorHandler";
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput"
import { isCbuValid } from "./CbuUtils";

const AccountBankArConfigComponent = forwardRef<PaymentMethodComponentRef, PaymentMethodComponentProps> ((props, ref) => {
    const [cbuText, setCbuText] = useState<string>('')
    const [cbuStatus, setCbuStatus] = useState<boolean>(false)
    const [paymentData, setPaymentData] = useState<any>();

    const updateCbu = (cbu: string) => {
        const normalizedcbu = cbu.replace(/[^\d]/g, '');
        setCbuText(normalizedcbu);
        props.changeCbuText(normalizedcbu);
        setCbuStatus(isCbuValid(normalizedcbu));
        props.onChangeStatus(isCbuValid(normalizedcbu));
    } 

    return (
        <div className="checkOutPayment-container-main-cbuContainer">
          <CustomTextInput
            label='Número de CBU'
            placeholder='Ingresá los 22 dígitos sin espacios'
            value={cbuText}
            changeText={updateCbu}
            maxLength={22}
            password
            success={cbuStatus}
            required
          />
        </div>
      )
});

export {AccountBankArConfigComponent};


import React, { useState, useEffect } from 'react'
import './CardSelectorCheckOutComponent.css'
import { useMediaQuery } from 'react-responsive'
import { getEmisoras } from '../../../net/Connector';
import axios from '../../../actions/instanceActions';
import { genericErrorHandler } from '../../../util/GenericErrorHandler';

let isTabletOrMobile = false;
const CardSelectorCheckOutComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const [logo, setLogo] = useState<[]>([])

    useEffect(() => {
        const getEmisorBanks = async () => {
            try {
                const response = await axios.get(getEmisoras());
                setLogo(response.data)
            } catch (error) {
                genericErrorHandler(error)
            }
        }
        getEmisorBanks();
    }, [])

    return (
        <div
            className={props.selected ? "cardSelectorCheckOutComponent-containerSelected" : "cardSelectorCheckOutComponent-container"}
            style={{
                opacity: props.disabled ? 0.4 : 1,
                cursor: props.disabled ? 'auto' : 'pointer',
                borderTopRightRadius: props.index === 0 ? 15 : 0,
                borderTopLeftRadius: props.index === 0 ? 15 : 0,
                borderBottomRightRadius: props.isLast ? 15 : 0,
                borderBottomLeftRadius: props.isLast ? 15 : 0,
            }}
            onClick={() => props.disabled ? {} : props.cardSelected()}
        >
            <div
                id={`q_${props.name}`}
                className="cardSelectorCheckOutComponent-container-circle"
                style={{ cursor: props.disabled ? 'auto' : 'pointer' }}
            >
                <div
                    className="cardSelectorCheckOutComponent-container-circle-innerCircle"
                    style={{
                        transform: props.selected ? `scale(${1.2})` : `scale(${0})`
                    }}
                ></div>
            </div>
            {
                props.options ?
                    <span className="material-symbols-outlined" style={{ color: 'var(--primary500)' }} >{props.icon}</span>
                    :
                    logo.map((cards: any) => {
                        if (props.icon === cards.code) {
                            return (
                                <img style={{ width: 50 }} src={cards.logotype} />
                            )
                        }
                    })
            }
            <p className="cardSelectorCheckOutComponent-container-name">{props.name}</p>
        </div>
    );
}

export default CardSelectorCheckOutComponent;


import React from 'react'
import './AtencionAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const AtencionAlert = (props: any) => {
  return (
    <div className="atencionAlert-container">
        <div className="atencionAlert-container-close" onClick={() => props.close()}>
            <span className="material-symbols-outlined">close</span>
        </div>
        <div className="atencionAlert-container-data">
            <p className="bold" style={{ color: 'var(--primary500)', textAlign: 'center' }}>¿CÓMO PODEMOS AYUDARTE?</p>
        </div>
        <div className="atencionAlert-container-data">
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>Comunicate con nuestro canal de atención de Lunes a Viernes de 8 a 20 h al</b>
            <b className="regular large" style={{ color: 'var(--primary500)' }}>0810-666-0066</b>
            <b className="regular large" style={{ color: 'var(--gray500)' }}>o a través de nuestro whatsapp</b>
        </div>
        <div className="atencionAlert-container-botton">
            <MainButton
                id='CONTACTATE POR WHATSAPP'
                fill
                color='var(--secondary500)'
                text='CONTACTATE POR WHATSAPP'
                onButtonPressed={() => {
                    props.close()
                    window.open("https://api.whatsapp.com/send?phone=541123646994&text=%C2%A1Hola!%F0%9F%91%8BQuer%C3%ADa%20consultar%20sobre...")
                }}
            />
        </div>
    </div>
  );
}

export default AtencionAlert;
import { useState, forwardRef, useEffect } from "react"
import { PaymentMethodComponentProps, PaymentMethodComponentRef } from "../PaymentMethodComponent";
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput"
import { isCbuValid } from "./CbuUtils";
import './AccountBankArCompleteConfigComponent.css';
import { useMediaQuery } from "react-responsive";
import SelectorSearcher from "../../components/generalComponents/selectorSearcher/SelectorSearcher";

let isTabletOrMobile = false;
const AccountBankArCompleteConfigComponent = forwardRef<PaymentMethodComponentRef, PaymentMethodComponentProps> ((props, ref) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [cbuText, setCbuText] = useState<string>('')
    const [titularText, setTitularText] = useState<string>('')
    const [nroDocumento, setNroDocumento] = useState<string>('')
    const [tipoDocumento, setTipoDocumento] = useState<any>(null)

    const [tipoDocumentoList, setTipoDocumentoList] = useState<any>(null)

    const [cbuStatus, setCbuStatus] = useState<boolean>(false)

    useEffect(() => {
      const ready = !isDisabled();
      props.onChangeStatus(ready);
    })

    useEffect(() => {
      hardCodeLists();
    }, [])

    const hardCodeLists = () => {
      const tipoDocuListAux: any = [
          {
              descripcion: 'D.N.I.',
              codigo: 6
          },
          {
              descripcion: 'C.D.I.',
              codigo: 1
          },
          {
              descripcion: 'L.E.',
              codigo: 3
          },
          {
              descripcion: 'L.C.',
              codigo: 4
          },
          {
              descripcion: 'PASAPORTE',
              codigo: 5
          },
          {
              descripcion: 'C.U.I.T.',
              codigo: 8
          },
          {
              descripcion: 'C.U.I.L.',
              codigo: 9
          }
      ]
      setTipoDocumentoList(tipoDocuListAux);
  }

    const updateCbu = (cbu: string) => {
        const normalizedcbu = cbu.replace(/[^\d]/g, '');
        setCbuText(normalizedcbu);
        props.changeCbuText(normalizedcbu);
        setCbuStatus(isCbuValid(normalizedcbu));
    } 

    const updateTitular = (titular: string) => {
      setTitularText(titular);
      props.changeCbuTitularText(titular);
    } 

    const isDisabled = () => {
      if(tipoDocumento?.codigo === 8 || tipoDocumento?.codigo === 9) {
        return (!cbuStatus || 
          titularText === '' || 
          nroDocumento?.length !== 11 || 
          !tipoDocumento )  
      }
      return (!cbuStatus || 
        titularText === '' || 
        nroDocumento?.length !== 8 || 
        !tipoDocumento )
    }

    return (
        <div className="accountBankArCompleteConfigComponent-container">
          <CustomTextInput
            label='Número de CBU'
            placeholder='Ingresá los 22 dígitos sin espacios'
            value={cbuText}
            changeText={updateCbu}
            maxLength={22}
            password
            success={cbuStatus}
            error={cbuText?.length !== 0 && !cbuStatus}
            errorMessage='El CBU debe tener 22 caracteres'
            required
          />
          <CustomTextInput
            label='Nombre del titular'
            value={titularText}
            changeText={updateTitular}
            required
          />
          <div className="accountBankArCompleteConfigComponent-container-selectorWrapper" style={{ opacity: !tipoDocumentoList ? 0.5 : 1 }}>
            <p className="accountBankArCompleteConfigComponent-container-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
            <SelectorSearcher
              widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
              id='Selector Tipo de documento'
              list={tipoDocumentoList}
              onValueSelected={(a: any) => {
                props.tipoDniCbuChanged(a)
                setTipoDocumento(a)
              }}
              selectedItem={tipoDocumentoList && tipoDocumento ? tipoDocumentoList?.filter((a: any) => a.codigo == tipoDocumento?.codigo)[0]?.descripcion : ''}
              placeholder='Seleccionar'
              deleteSelectedItem={() => {
                props.tipoDniCbuChanged(null)
                setTipoDocumento(null)
                props.dniCbuChanged('')
                setNroDocumento('')
              }}
              disabled={!tipoDocumentoList}
              noSearch
            /> 
          </div>
          <CustomTextInput
            label='Nro de documento'
            required
            changeText={(e: string) => {
                props.dniCbuChanged(e.replace(/[^0-9]+/g, ''))
                setNroDocumento(e.replace(/[^0-9]+/g, ''))
            }}
            value={nroDocumento}
            success={tipoDocumento?.codigo === 8 || tipoDocumento?.codigo === 9 ? nroDocumento?.length === 11 : nroDocumento?.length === 8}
            placeholder='Sólo números sin puntos'
            error={tipoDocumento?.codigo === 8 || tipoDocumento?.codigo === 9 ? nroDocumento?.length !== 11 && nroDocumento !== '' : nroDocumento?.length !== 8 && nroDocumento !== ''}
            maxLength={tipoDocumento?.codigo === 8 || tipoDocumento?.codigo === 9 ? 11 : 8}
            disabled={!tipoDocumento}
          />
        </div>
      )
});

export {AccountBankArCompleteConfigComponent};


import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import './CheckoutFin.css'
import success from '../../assets/lotties/success.json'
import error from '../../assets/lotties/errorPago.json'
import Lottie from 'react-lottie';
import logo from '../../assets/images/svgs/logoColor.svg'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useMediaQuery } from 'react-responsive';
import { getPolicyInfo } from '../../actions';
import successWaveDesktop from '../../assets/images/svgs/adminSuccessDesktop.svg'
import errorWaveDesktop from '../../assets/images/svgs/adminErrorDesktop.svg'
import successWaveMobile from '../../assets/images/svgs/adminSuccessMobile.svg'
import errorWaveMobile from '../../assets/images/svgs/adminErrorMobile.svg'

let isTabletOrMobile = false;
const CheckoutFin = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();
  const [estado, setEstado] = useState<any>(null);
  const [productId, setProductId] = useState<any>(null);
  const [productData, setProductData] = useState<any>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleUrlParams()
  }, [])

  useEffect(() => {
    getPolicyInfo(productId, setProductData)
  }, [productId])

  const handleUrlParams = () => {
    const estado = searchParams.get("estado");
    const id = searchParams.get('id');
    setEstado(estado)
    if (estado === 'Exito') setProductId(id);
  }

  const estadoColor = () => {
    if(estado === 'Exito') return 'var(--statusSuccessPrimary)'
    return 'var(--statusErrorPrimary)'
  }

  const lottieSize = estado === 'Exito' ? 105 : 105

  const extractErrorMessage = (message, keyword) => {
    const startIndex = message.indexOf(keyword);
    if (startIndex !== -1) {
      return message.substring(startIndex + keyword.length).trim();
    }
    return "Error";
  };

  return (
    <div className="checkoutFin-container">
      <div className="checkoutFin-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: estado === 'Exito' ? success : error,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={lottieSize}
          width={lottieSize}
          isStopped={false}
          isPaused={false}
        />
        <img src={logo} className="checkoutFin-container-main-img"/>
        <h5 className="checkoutFin-container-main-title light">{estado === 'Exito' ? '¡Felicidades!' : '¡Algo salió mal!'}</h5>
        <h5 className="checkoutFin-container-main-subTitle regular" style={{ color: estado === 'Exito' ? 'var(--statusSuccessPrimary)' : 'var(--gray700)' }}>{estado === 'Exito' ? 'Tu nro de póliza es ' + productData?.number : 'No pudimos completar el proceso de la cobertura'}</h5>
        <b className="checkoutFin-container-main-description regular large">{estado === 'Exito' ? 'En breve te enviaremos a tu email la póliza con todos los detalles de tu cobertura.' : 'El motivo es: ' + extractErrorMessage(props.checkOutConfirmErrorMessage, 'Error al emitir:') + ' Lamentamos este inconveniente, este error es poco habitual. Por favor, reiniciá el proceso y probá de nuevo.'}</b>
        {estado === 'Exito' && <div className="checkoutFin-container-main-bot">
          <MainButton
            id='DESCARGAR PÓLIZA'
            fill
            color='var(--secondary500)'
            disabled={!productData?.url}
            text='DESCARGAR PÓLIZA'
            onButtonPressed={() => window.open(productData?.url)}
          />
        </div>}
        <div className="checkoutFin-container-main-bot">
          <MainButton
            id='VOLVER AL HOME'
            border={estado === 'Exito'}
            fill={estado === 'Error'}
            color='var(--secondary500)'
            text='VOLVER AL HOME'
            onButtonPressed={() => navigate('/inicio')}
          />
        </div>
      </div>
      <div className="checkoutFinAdmin-container-wave">
        {isTabletOrMobile ? 
          <img src={estado === 'Exito' ? successWaveMobile : errorWaveMobile} className="checkoutFinAdmin-container-wave-img"/>
          :
          <img src={estado === 'Exito' ? successWaveDesktop : errorWaveDesktop} className="checkoutFinAdmin-container-wave-img"/>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    checkOutConfirmErrorMessage: state.checkOut.checkOutConfirmErrorMessage,
  };
};

export default connect(mapStateToProps, {})(CheckoutFin);
import React, {useEffect, useState} from 'react'
import './FormFieldGeo.css'
import GoogleMapReact from 'google-map-react';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import { useMediaQuery } from 'react-responsive';
import { V2_getQuote, actionGetCoordinates, actionQuoteQuestions, getGeoData } from '../../../../../actions';

let isTabletOrMobile = false;
const FormFieldGeo = (props) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //INPUTS
    const [codigoPostal, setCodigoPostal] = useState(null);
    const [calle, setCalle] = useState('');
    const [nro, setNro] = useState('');
    const [piso, setPiso] = useState('');
    const [dpto, setDpto] = useState('');

    const [quoteData, setQuoteData] = useState(null);

    //COORDINATES
    const [valueMapCoordinates, setValueMapCoordinates] = useState({
        center: null,
        zoom: props.data.input.config.zoom
    });
    const [initialMapCoordinates, setInitialMapCoordinates] = useState({
        center: {
            lat: parseFloat(props.data?.input?.config?.startPos[0]),
            lng: parseFloat(props.data?.input?.config?.startPos[1])
        }, 
        zoom: props.data.input.config.zoom
    });

    //LISTS
    const [codigoPostalList, setCodigoPostalList] = useState(null);

    //OTHER
    const googleApiKey = "AIzaSyDtIR6V9MxunQYEurGdpeqOLtlr_GkHZ9Q";
    const [loadingCoordinates, setLoadingCoordinates] = useState(false);
    const [timeout, timeoutChange] = useState(0);
    const [geocodeError, setGeocodeError] = useState(null);
    const [excedingLength, setExcedingLength] = useState(false);

    useEffect(() => {
        if(props.checkOutData) V2_getQuote(props.checkOutData?.offer?.product?.categoryId, props.checkOutData?.offer?.quoteId, setQuoteData)
    }, [props.checkOutData])

    useEffect(() => {
        if(quoteData) {
            setCodigoPostal(quoteData?.codigoPostal)
        }
    }, [quoteData])

    useEffect(() => {
        getGeoData(setCodigoPostalList)
        actionQuoteQuestions(props.checkOutData?.offer?.product?.categoryId, props.checkOutData?.offer?.quoteId, setQuoteData)
    }, [])

    useEffect(() => {
        if(valueMapCoordinates?.center && calle?.length > 0 && nro?.length > 0 && codigoPostal && !excedingLength) {
            props.setDisableContinuar(false)
            const data = {
                "calleVivienda": calle,
                "nroVivienda": nro,
                "latitud": valueMapCoordinates?.center?.lat,
                "longitud": valueMapCoordinates?.center?.lng,
                "pisoVivienda": piso,
                "dptoVivienda": dpto,
                "codigoPostalVivienda": codigoPostal
            }
            props.onUpdate(JSON.stringify(data))
        } else props.setDisableContinuar(true)
    }, [calle, nro, valueMapCoordinates?.center, piso, dpto, codigoPostal])

    useEffect(() => {
        if (calle != '' && nro != '') {
            handleSearchTextChange(calle + '%20' + nro + '%20' + codigoPostalList?.filter((a) => a.codigoPostal == codigoPostal)[0]?.descripcion + '%20' + codigoPostalList?.filter((a) => a.codigoPostal == codigoPostal)[0]?.codigoPostal);
        }
        if (calle?.length + nro?.length + piso?.length + dpto?.length + 3 > 30) setExcedingLength(true)
        else setExcedingLength(false)
    }, [calle, nro])

    const handleSearchTextChange = (text) => {
        setLoadingCoordinates(true);
        if (timeout) timeoutChange(clearTimeout(timeout));
        timeoutChange(
            setTimeout(() => {
                actionGetCoordinates(text, 'AR', setValueMapCoordinates, setLoadingCoordinates, setGeocodeError);
            }, 2000)
        );
    };
    
    return (
        <div className="FormFieldGeo-container">
            <h6 className="FormFieldGeo-container-title bold">{props.data?.display?.description}</h6>
            <div className="FormFieldGeo-container-inputs">
                <div className="FormFieldGeo-container-inputs-selectorWrapper" style={{ opacity: quoteData ? 0.5 : 1 }}>
                    <p className="FormFieldGeo-container-inputs-selectorWrapper-title bold">Localidad o código postal&nbsp;<ul>*</ul></p>
                    <SelectorSearcher
                        widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                        id='Selector Localidad o código postal' 
                        list={codigoPostalList}
                        onValueSelected={(a) => setCodigoPostal(a.codigoPostal)}
                        selectedItem={codigoPostalList && codigoPostal ? codigoPostalList?.filter((a) => a.codigoPostal == codigoPostal)[0]?.descripcion + ' (' + codigoPostalList?.filter((a) => a.codigoPostal == codigoPostal)[0]?.codigoPostal + ')' : ''} 
                        placeholder='Seleccionar' 
                        deleteSelectedItem={() => setCodigoPostal(null)}
                        disabled={quoteData}
                        cp
                    /> 
                </div>
                <div className="FormFieldGeo-container-inputs-wrapper">
                    <CustomTextInput
                        label='Calle'
                        required
                        changeText={(e) => setCalle(e)}
                        value={calle}
                        success={calle !== ''}
                        error={geocodeError || excedingLength}
                        shake={(calle === '' || excedingLength) && props.shake}
                    />
                    {geocodeError && <b className="FormFieldGeo-container-inputs-wrapper-error regular small">{geocodeError}</b>}
                    {excedingLength && <b className="FormFieldGeo-container-inputs-wrapper-error regular small">Máximo 30 caracteres entre calle, nro, piso y dpto</b>}
                </div>
                <div className="FormFieldGeo-container-inputs-part">
                    <CustomTextInput
                        label='Nro'
                        required
                        changeText={(e) => setNro(e.replace(/[^0-9]+/g, ''))}
                        value={nro}
                        success={nro !== ''}
                        error={geocodeError || excedingLength}
                        shake={(nro === '' || excedingLength) && props.shake}
                    />
                    <CustomTextInput
                        label='Piso'
                        changeText={(e) => setPiso(e)}
                        value={piso}
                        error={excedingLength}
                        success={piso !== ''}
                        shake={excedingLength && props.shake}
                    />
                    <CustomTextInput
                        label='Dpto.'
                        changeText={(e) => setDpto(e)}
                        value={dpto}
                        error={excedingLength}
                        success={dpto !== ''}
                        shake={excedingLength && props.shake}
                    />
                </div>
            </div>
            <div className={(loadingCoordinates && props.shake) ? "FormFieldGeo-container-map shake-animation" : "FormFieldGeo-container-map"}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApiKey }}
                    center={valueMapCoordinates?.center ? valueMapCoordinates?.center : initialMapCoordinates?.center}
                    zoom={initialMapCoordinates?.zoom}
                    options={{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false
                    }}
                > 

                    <div 
                        className="FormFieldGeo-container-map-markerContainer"
                        lat={valueMapCoordinates?.center?.lat ? valueMapCoordinates?.center?.lat : initialMapCoordinates?.center?.lat}
                        lng={valueMapCoordinates?.center?.lng ? valueMapCoordinates?.center?.lng : initialMapCoordinates?.center?.lng}
                    >
                        <span className="material-symbols-outlined" style={{ fontSize: 50, color: 'var(--primary500)' }}>location_on</span>
                    </div>
                </GoogleMapReact>
                {loadingCoordinates && 
                    <div className="FormFieldGeo-container-map-loaderWrapper">
                        <div className="FormFieldGeo-container-map-loaderWrapper-loader"></div>
                    </div>
                }
            </div>
        </div>
    );
}

export default FormFieldGeo;
import React from 'react'
import './VerMasModal.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const VerMasModal = (props: any) => {

  return (
    <div className="verMasModal-container">
      <div 
        className="verMasModal-container-close"
        onClick={() => props.close()}
      >
        <span className="material-symbols-outlined">close</span>
      </div>
      <p className="verMasModal-container-title bold">¿Que incluye tu plan?</p>
      <div className="verMasModal-container-info" dangerouslySetInnerHTML={{__html: props.info?.inclusions}} />
      <div className="verMasModal-container-boton">
        <MainButton
          id='ACEPTAR'
          fill
          color='var(--secondary500)'
          text='ACEPTAR'
          onButtonPressed={() => props.close()}
        />
      </div>
    </div>
  )
}

export default VerMasModal;
import { useState, useEffect } from 'react'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import './CheckOutLogin.css'
import { GoogleLogin } from 'react-google-login';
import {
  loginGoogleUser
} from '../../../actions'
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetGlobalParams } from '../../../util/GetGlobalParams';
import { GetCountryUrl } from '../../../util/GetCountryUrl';

let link = '';
const CheckOutLogin = (props: any) => {

  const navigate = useNavigate();
  const [emailLogin, setEmailLogin] = useState<string>('')
  const [contraseñaLogin, setContraseñaLogin] = useState<string>('');

  const location: any = useLocation();
  const pathName = location.pathname;
  const query = location.search;
  link = pathName + query;



  useEffect(() => {
    if (props.quoteData !== '') {
      setEmailLogin(props.quoteData?.email)
    }
  }, [props.quoteData])

  const disableLogin = () => {
    return !(emailLogin !== '' && contraseñaLogin !== '')
  }

  return (
    <div className="checkOutLogin-container">
      <div 
        className="checkOutLogin-container-volver"
        onClick={() => props.setLoggingIn(false)}
      >
        <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
        <b className="checkOutLogin-container-volver bold medium">Volver</b>
      </div>
      <div className="checkOutLogin-container-main">
        <h6 className="checkOutLogin-container-main-title bold">Ingresá tu e-mail y contraseña para continuar</h6>
        <CustomTextInput
          label='E-mail'
          placeholder={useIntl().formatMessage({ id: 'component.checkOut.login.email.placeholder' })}
          changeText={(e: string) => setEmailLogin(e)}
          value={emailLogin}
        />
        <CustomTextInput
          label='Contraseña'
          placeholder='*********'
          changeText={(e: string) => setContraseñaLogin(e)}
          value={contraseñaLogin}
          password
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              if (!disableLogin()) {
                props.loginUser(emailLogin, contraseñaLogin)
              }
            }
          }}
        />
        <b
          className="checkOutLogin-container-main-recuperar medium" 
          onClick={() => navigate('/recovery?redirectTo=' + link, { state: { link: link }} )}
        >¿Olvidaste tu contraseña?</b>

        <div className="checkOutLogin-container-main-buttonWrapper">
          <MainButton
            id='CONTINUAR Boton'
            disabled={disableLogin()}
            text="CONTINUAR"
            onButtonPressed={() => props.loginUser(emailLogin, contraseñaLogin)}
            altStyle10
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  loginGoogleUser
})(CheckOutLogin);
import {
    LOADING,
} from './types';
import {
    netCodigosPostales,
    netGenericForm,
    netGenericFormNoCap,
    netNacionalidades,
    netProfesiones,
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const V2_getGenericForm = (type: any, setFormList: any) => {
    axios.get(netGenericForm(type))
        .then((response) => {
            setFormList(response.data)
        })
        .catch((error) => {
            genericErrorHandler(error)
        })
}

export const getGenericForm = (type: any, setFormList: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netGenericForm(type))
            .then((response) => {
                setFormList(response.data)
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const V2_postGenericForm = (type: any, data: any, navigate: any, setLoading: any) => {
    setLoading(true)
    axios({
        url: netGenericFormNoCap(type),
        method: 'post',
        data: data,
    })
        .then((response) => {
            navigate('/oferta-personalizada-enviado')
            setLoading(false)
        })
        .catch((error) => {
            genericErrorHandler(error)
            setLoading(false)
        })
}

export const postGenericForm = (type: any, data: any, reCaptcha: any, recaptchaRef: any, setReCaptcha: any, navigate: any, navigateTo: string, countryId: number, setContactSuccess: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netGenericForm(type),
            method: 'post',
            headers: { 'captcha': reCaptcha },
            data: data,
        })
            .then((response) => {
                if (setContactSuccess) setContactSuccess(true)
                else navigate(GetCountryUrl(countryId) + navigateTo)
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                recaptchaRef.current.reset();
                setReCaptcha(null);
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const getGeoData = (setCodigoPostalList: any) => {
    axios.get(netCodigosPostales())
        .then(resCP => {
            setCodigoPostalList(resCP.data);
        })
        .catch(error => {
            genericErrorHandler(error)
        });
}

export const actionPersonDataFinearLists = (setNacionalidadList: any, setProfesionList: any, setCodigoPostalList: any, insurerId: any) => {
    axios.get(netNacionalidades(insurerId))
        .then(resNac => {
            setNacionalidadList(resNac.data);
            axios.get(netProfesiones(insurerId))
                .then(resPro => {
                    setProfesionList(resPro.data);
                    axios.get(netCodigosPostales())
                        .then(resCP => {
                            setCodigoPostalList(resCP.data);
                        })
                        .catch(error => {
                            genericErrorHandler(error)
                        });
                })
                .catch(error => {
                    genericErrorHandler(error)
                });
        })
        .catch(error => {
            genericErrorHandler(error)
        });
}
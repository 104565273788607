import { useState } from 'react';
import './FormFieldText.css';
import MainButton from '../../../MainButton/MainButton';
import CustomTextInput from '../../../customTextInput/CustomTextInput';

const FormFieldText = (props: any) => {

  const [localText, setLocalText] = useState<string>('')

  const handleSendData = (res: any) => {
    if(props.parent === 'Cotizar') {
      const data = {
        "questionId": props.data?.id,
        "value": res,
      }
      props.onUpdate(data)
    } else {
      props.onUpdate(res)
    }
  }

  return (
    <div className="formFieldText-container" style={{ width: props.parent === 'Cotizar' ? '33%' : '100%' }}>
      <CustomTextInput
        maxLength={props.data?.input?.config?.max}
        label={props.data?.display?.name}
        required={props.data?.required}
        placeholder={props.data?.display?.placeholder}
        value={localText}
        changeText={(e: string) => {
          let res = e.replace(/[^a-z0-9 ]/gi, '')
          if (props.data?.display?.name === 'Patente' ) res = res.toUpperCase()
          setLocalText(res)
          handleSendData(res)
        }}
      />
      {props.parent === 'Cotizar' && <div className="formFieldText-container-button">
        <MainButton
          id='CONTINUAR'
          fill
          color='var(--primary500)'
          text='CONTINUAR'
          onButtonPressed={() => {
            const data = {
              "questionId": props.data?.id,
              "value": localText,
            }
            props.next(data)
          }}
        />
      </div>}
    </div>
  )
}

export default FormFieldText;

import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './BannerSection.css'
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import banner1 from '../../../assets/images/banner1.png';
import banner from '../../../assets/images/banner1-1.png';

let isTabletOrMobile = false;
const BannerSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  return (
    <div className="bannerSection-container">
      <img className="bannerSection-container-img" src={isTabletOrMobile ? banner : banner1} />
      <div className="bannerSection-container-main">
        <h4 className="bannerSection-container-main-title bold">¡Te damos la bienvenida!</h4>
        <div className="bannerSection-container-main-mutliText">
          <h1 className='bannerSection-container-main-mutliText-textOneColor bold'>Descubrí&nbsp;</h1>
          <h1 className='bannerSection-container-main-mutliText-textOne bold'>una nueva forma</h1>
        </div>
        <h1 className='bannerSection-container-main-textTwo regular'>de cuidar tu hogar</h1>
        {isTabletOrMobile ? 
          <h4 className='bannerSection-container-main-textThree regular'>Te brindamos los planes que más se adaptan<br/>a tus necesidades. Contratá tu cobertura de forma rápida, sencilla y 100% online.</h4>
          :
          <h4 className='bannerSection-container-main-textThree regular'>Te brindamos los planes que más se adaptan<br/>a tus necesidades. Contratá tu cobertura de forma rápida,<br/>sencilla y 100% online.</h4>
        }
        <div className='bannerSection-container-main-buttonContainer'>
          <MainButton
            id='VER COBERTURAS'
            fill
            color='var(--secondary500)'
            text='VER COBERTURAS'
            onButtonPressed={() => props.buttonPress()}
          />
        </div>
      </div>
    </div>
  );
}

export default BannerSection;
import React, { useEffect, useState } from 'react'
import './ContactModal.css'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from 'react-lottie';
import successLottie from '../../../assets/lotties/success.json'
import { V2_getQuote } from '../../../actions';

const ContactModal = (props: any) => {

  const [celular, setCelular] = useState<string>('')
  const [nombre, setNombre] = useState<string>('')
  const [apellido, setApellido] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [quoteData, setQuoteData] = useState<any>(null)

  useEffect(() => {
    V2_getQuote(props.categoryId, props.quoteId, setQuoteData)
  }, [])

  useEffect(() => {
    if(quoteData) {
      setEmail(quoteData?.email)
      setCelular(quoteData?.codigoArea + quoteData?.telefono)
    }
  }, [quoteData])

  const isDisabled = () => {
    return nombre === '' || celular === '' || !props.reCaptcha || apellido === '' || !validateEmail(email)
  }

  const validateEmail = (e: string) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e).toLowerCase())) {
        return false;
    } return true
  }

  return (
    <>
      {props.success ?
        <div className="contactModal-containerSucc">
          <div 
            className="contactModal-container-top-close"
            onClick={() => props.close()}
          >
            <span className="material-symbols-outlined">close</span>
          </div>
          <div className="contactModal-container-lottie">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: successLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={100}
              width={120}
              isStopped={false}
              isPaused={false}
            />
          </div>
          <p className="contactModal-container-top-title bold">¡Excelente!</p>
          <b className="contactModal-container-subTitle regular large">Muchas gracias por tu interés.<br/>En breve te llamamos.</b>
          <div className="contactModal-container-boton">
            <MainButton
              id='ACEPTAR'
              fill
              color='var(--secondary500)'
              text='ACEPTAR'
              onButtonPressed={() => props.close()}
            />
          </div>
        </div>
        :
        <div className="contactModal-container">
          <div className="contactModal-container-top">
            <p className="contactModal-container-top-title bold">{props.title}</p>
            <div 
              className="contactModal-container-top-close"
              onClick={() => props.close()}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
          <b className="contactModal-container-subTitle regular large">{props.subTitle}</b>
          <div className="contactModal-container-inputList">
            <CustomTextInput
              label='Nombre'
              required
              changeText={(e: string) => setNombre(e)}
              value={nombre}
            />
            <CustomTextInput
              label='Apellido'
              required
              changeText={(e: string) => setApellido(e)}
              value={apellido}
            />
            <CustomTextInput
              label='Celular'
              required
              changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
              value={celular}
              maxLength={10}
            />
            <CustomTextInput
              label='Email'
              required
              changeText={(e: string) => setEmail(e)}
              value={email}
              error={email !== '' && !validateEmail(email)}
              errorMessage='Email inválido'
              placeholder='nombre@ejemplo.com'
            />
          </div>
          <div className="contactModal-container-finish">
            <div className="contactModal-container-finish-captcha">
              <ReCAPTCHA
                ref={props.recaptchaRef}
                sitekey="6LdFokQfAAAAAMQ3MMEbQmaltswGk50-MKQoaS8m"
                onChange={props.setReCaptcha}
              />
            </div>
            <div className="contactModal-container-finish-botons">
              <MainButton
                id='CANCELAR'
                empty
                color='var(--secondary500)'
                text='CANCELAR'
                onButtonPressed={() => props.close()}
              />
              <MainButton
                id='FINALIZAR'
                fill
                color='var(--secondary500)'
                text='FINALIZAR'
                disabled={isDisabled()}
                onButtonPressed={() => {
                  const data = {
                    mail: email,
                    cellphone: celular,
                    name_lastname: apellido + ', ' + nombre,
                    contact_message: 'Quiero que me llamen - ' + props.title + ' - ' + props.subTitle
                  }
                  props.sendContact(data)
                }}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ContactModal;
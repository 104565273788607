
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './CategoryListSection.css'
import CategoriaButton from '../../../components/cotizarComponents/CategoriaButton/CategoriaButton';
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const CategoryListSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  const navigate = useNavigate();

  return (
    <div className="categoryListSection-container" ref={props.refCategory}>
      {props.categoriaList?.length !== 0 && 
        <div className='categoryListSection-container-text'>
          <h4 className='categoryListSection-container-text-title bold'>¿Qué cobertura estás buscando?</h4>
          <b className='categoryListSection-container-text-subtitle regular large'>Descubrí las mejores opciones para tu hogar</b>
        </div>
      }
      <div className='categoryListSection-container-list'>
        {props.categoriaList?.map((categoryDB: any, index: Number) => (
          <CategoriaButton
            key={index}
            id={categoryDB.name + ' boton'}
            name={categoryDB.name} 
            categoryId={categoryDB.category.id}
            backImage={categoryDB.category.imageIcon}
            image={categoryDB.category.imageIconSelected}
            categoryPressed={() => {
              props.categoriaSelectedChange(categoryDB.category.id)
              const adminRoute = props.admin ? '&admin=True' : ''
              navigate('/cotizar?cId=' + categoryDB.category.id + adminRoute)
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default CategoryListSection;
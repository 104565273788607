import React, { useEffect, useState } from 'react'
import './CheckoutDetailsProduct.css'
import { V2_fetchCategoriaList } from '../../../actions';

const CheckoutDetailsProduct = (props: any) => { 

  const [categoryList, setCategoryList] = useState<any>();
  const [currentCategory, setCurrentCategory] = useState<any>();

  useEffect(() => {
    V2_fetchCategoriaList(setCategoryList);
  },[])

  useEffect(() => {
    if(categoryList) setCurrentCategory(categoryList?.filter((a: any) => a?.category?.id === props.checkOutData?.offer?.product?.categoryId)[0]?.category)
  }, [categoryList])

  const getProductName = (name: string) => {
    if(name === "HOGAR -INCENDIO-") return "HOGAR<br/>-INCENDIO-";
    else if(name === "HOGAR -MULTICOBERTURA-") return "HOGAR<br/>-MULTICOBERTURA-";
    return name;
  }

  return (
    <div className="checkoutDetailsProduct-container">
      <div className="checkoutDetailsProduct-container-period">
        <b className="checkoutDetailsProduct-container-period-text regular medium">Vigencia semestral</b>
      </div>
      <div className="checkoutDetailsProduct-container-data">
        <div className="checkoutDetailsProduct-container-data-texts">
          <b className="checkoutDetailsProduct-container-data-texts-title regular large">{props.checkOutData?.offer?.product?.title}</b>
          <h6 className="checkoutDetailsProduct-container-data-texts-plan bold">{props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.description}</h6>
          <div className="checkoutDetailsProduct-container-data-texts-insurer">
            <b className="checkoutDetailsProduct-container-data-texts-insurer-text regular medium">Asegura</b>
            <img src={props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0]?.insurer?.image} className="checkoutDetailsProduct-container-data-texts-insurer-image"/>
          </div>
        </div>
        <div className="checkoutDetailsProduct-container-data-image"> 
          <img className="checkoutDetailsProduct-container-data-image-image" src={currentCategory?.imageIconSelected} alt=""/>
          <p className='checkoutDetailsProduct-container-data-image-text bold' dangerouslySetInnerHTML={{__html: getProductName(currentCategory?.name.toUpperCase())}}/>
          <img className="checkoutDetailsProduct-container-data-image-backGroundImage" src={currentCategory?.imageIcon} alt=""/>
        </div>
      </div>
    </div>
  );
}

export default CheckoutDetailsProduct;
import React, { useEffect, useState } from 'react'
import './FormFieldListItem.css'

const FormFieldListItem = (props: any) => {

    const [key, setKey] = useState<any>(0);
    const [localCurrentAnser, setLocalCurrentAnser] = useState<any>(null);

    useEffect(() => {
        setKey(key + 1)
        setLocalCurrentAnser(props.currentAnswer?.inmobiliariaIncendio)
    }, [props.currentAnswer?.inmobiliariaIncendio])

    useEffect(() => {
        setKey(key + 1)
        setLocalCurrentAnser(props.currentAnswer?.inmobiliariaMulticobertura)
    }, [props.currentAnswer?.inmobiliariaMulticobertura])

    const generateItemName = (a: any) => {
        if (props.parent === 'CheckOut') {
            let inputString = a.label;
            let parts = inputString?.split(';');

            let firstPart = parts[0]?.trim();
            let secondPart = parts[1]?.trim();
            return (
                <div className="FormFieldListItem-container-texts">
                    <b className="FormFieldListItem-container-texts-text regular medium">{firstPart?.toUpperCase()}</b>
                    <b className="FormFieldListItem-container-texts-subText regular small">{secondPart}</b>
                </div>
            )
        } return <b className="FormFieldListItem-container-text regular medium">{a.label?.toUpperCase()}</b>
    }

    return (
        <div
            key={key} 
            id={props.item} 
            className="FormFieldListItem-container"
            onClick={() => props.handleItemSelected(props.item?.value)} 
            style={{ 
                width: props.width,
                borderBottom: localCurrentAnser === props.item?.value ? '1px solid var(--primary200)' : 'auto',
                backgroundColor: localCurrentAnser === props.item?.value ? 'var(--primary200)' : 'auto'
            }}
        >
            {generateItemName(props.item)}
        </div>
    );
}

export default FormFieldListItem;
import React, { useEffect, useState } from 'react'
import './OfertaPersonalizada.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import { 
  V2_getGenericForm,
  V2_getOffer,
  V2_postGenericForm,
  getGeoData
} from '../../actions';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import SelectorSearcher from '../../components/generalComponents/selectorSearcher/SelectorSearcher';
import { useMediaQuery } from 'react-responsive';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import Selector from '../../components/generalComponents/selector/Selector';

let isTabletOrMobile = false;
const OfertaPersonalizada = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [admin, setAdmin] = useState<boolean>(false);
  const [offerData, setOfferData] = useState<any>(null);
  const [quoteData, setQuoteData] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);

  const [nombre, setNombre] = useState<string>('');
  const [apellido, setApellido] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [localidad, setLocalidad] = useState<any>(null);
  const [calle, setCalle] = useState<string>('');
  const [nro, setNro] = useState<string>('');
  const [piso, setPiso] = useState<string>('');
  const [dpto, setDpto] = useState<string>('');
  const [vivienda, setVivienda] = useState<any>(null);

  const [uso, setUso] = useState<any>(null);
  const [metros, setMetros] = useState<string>('');
  const [cobertura, setCobertura] = useState<any>(null);

  const [localidadList, setLocalidadList] = useState<any>(null);
  const [coberturaList, setCoberturaList] = useState<any>(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleUrlParams();
    V2_getGenericForm('personal_offer', setFormData)
    getGeoData(setLocalidadList)
  }, [])

  useEffect(() => {
    if(formData) {
      assignData()
    }
  }, [formData])

  useEffect(() => {
    if(quoteData) {
      setLocalidad(quoteData?.codigoPostal)
      setEmail(quoteData?.email)
      setCelular(quoteData?.codigoArea + quoteData?.telefono)
    }
  }, [quoteData])

  const assignData = () => {
    setCoberturaList(formData?.filter((a: any) => a.code === "cobertura")[0]?.config?.values)
  }

  const handleUrlParams = () => {
    let admin: any = searchParams.get("admin")
    const offerId = searchParams.get('offer');
    const categoryId = searchParams.get('cId');
    if (admin) setAdmin(true)
    if(categoryId && offerId) {
      if(!props.offerData) {
        V2_getOffer(categoryId, offerId, setOfferData, setQuoteData, props.setLoading)
      }
    }
  };

  const isValidName = (e: string) => {
    if(e.length > 0) {
      if(!(/^\s/.test(e))) {
          return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
      }
    } return false
  }

  const validateEmail = (e: string) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(e).toLowerCase())) {
          return false;
      } return true
  }

  const isDisabled = () => {
    return ( 
      nombre === '' || apellido === '' || celular === '' || !validateEmail(email) || 
      localidad === null || calle === '' || nro === '' || !vivienda ||
      !uso || metros === '' || cobertura === null
    )
  }
  
  return (
    <div className="ofertaPersonalizada-container">
      {!admin && <Header home colored special />}
      <div className="ofertaPersonalizada-container-main">
        <h4 className="ofertaPersonalizada-container-main-title bold">Plan personalizado</h4>
        <b className="ofertaPersonalizada-container-main-subTitle regular large">Por favor, ingresá los datos necesarios para solicitar una cotización a tu medida</b>
        <div className="ofertaPersonalizada-container-main-inputList">
          <CustomTextInput
            label='Nombre'
            required
            changeText={(e: string) => setNombre(e)}
            value={nombre}
            success={isValidName(nombre)}
            error={nombre.length > 0 ? !isValidName(nombre) : false}
          />
          <CustomTextInput
            label='Apellido'
            required
            changeText={(e: string) => setApellido(e)}
            value={apellido}
            success={isValidName(apellido)}
            error={apellido.length > 0 ? !isValidName(apellido) : false}
          />
          <CustomTextInput
            label='Celular'
            required
            changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
            value={celular}
            success={celular !== ''}
          />
          <CustomTextInput
            label='Email'
            required
            changeText={(e: string) => setEmail(e)}
            success={validateEmail(email)}
            error={email.length > 0 ? !validateEmail(email) : false}
            errorMessage='E-mail inválido'
            placeholder='nombre@ejemplo.com'
            value={email}
          />
          <div className="ofertaPersonalizada-container-main-inputList-selectorWrapper">
            <p className="ofertaPersonalizada-container-main-inputList-selectorWrapper-title bold">Localidad o código postal&nbsp;<ul>*</ul></p>
            <SelectorSearcher
              widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
              id='Selector Localidad o código postal' 
              list={localidadList}
              onValueSelected={(a) => setLocalidad(a.codigoPostal)}
              selectedItem={localidadList && localidad ? localidadList?.filter((a) => a.codigoPostal == localidad)[0]?.descripcion + ' (' + localidadList?.filter((a) => a.codigoPostal == localidad)[0]?.codigoPostal + ')' : ''} 
              placeholder='Seleccionar' 
              deleteSelectedItem={() => setLocalidad(null)}
              cp
            /> 
          </div>
          <CustomTextInput
            label='Calle'
            required
            changeText={(e: string) => setCalle(e)}
            value={calle}
            success={calle !== ''}
          />
          <div className="ofertaPersonalizada-container-main-inputList-list">
            <CustomTextInput
              label='Nro'
              required
              changeText={(e: string) => setNro(e.replace(/[^0-9]+/g, ''))}
              value={nro}
              success={nro !== ''}
            />
            <CustomTextInput
              label='Piso'
              changeText={(e: string) => setPiso(e)}
              value={piso}
              success={piso !== ''}
            />
            <CustomTextInput
              label='Dpto.'
              changeText={(e: string) => setDpto(e)}
              value={dpto}
              success={dpto !== ''}
            />
          </div>
          <div className="ofertaPersonalizada-container-main-inputList-optionWrapper">
            <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-title bold">Tipo de vivienda&nbsp;<ul>*</ul></p>
            <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options">
              <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item">
                <div 
                  className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle"
                  onClick={() => vivienda === 'Casa' ? {} : setVivienda('Casa')}
                  style={{ cursor: vivienda === 'Casa' ? 'auto' : 'pointer' }}
                >
                  <div 
                    className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle-inner"
                    style={{ transform: vivienda === 'Casa' ? 'scale(1)' : 'scale(0)' }}
                  />
                </div>
                <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-text bold">Casa</p>
              </div>
              <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item">
                <div 
                    className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle"
                    onClick={() => vivienda === 'Departamento' ? {} : setVivienda('Departamento')}
                    style={{ cursor: vivienda === 'Departamento' ? 'auto' : 'pointer' }}
                  >
                    <div 
                      className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle-inner"
                      style={{ transform: vivienda === 'Departamento' ? 'scale(1)' : 'scale(0)' }}
                    />
                </div>
                <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-text bold">Departamento</p>
              </div>
            </div>
          </div>
          <div className="ofertaPersonalizada-container-main-inputList-optionWrapper">
            <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-title bold">Uso&nbsp;<ul>*</ul></p>
            <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options">
              <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item">
                <div 
                  className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle"
                  onClick={() => uso === 'Comercial' ? {} : setUso('Comercial')}
                  style={{ cursor: uso === 'Comercial' ? 'auto' : 'pointer' }}
                >
                  <div 
                    className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle-inner"
                    style={{ transform: uso === 'Comercial' ? 'scale(1)' : 'scale(0)' }}
                  />
                </div>
                <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-text bold">Comercial</p>
              </div>
              <div className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item">
                <div 
                    className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle"
                    onClick={() => uso === 'Particular' ? {} : setUso('Particular')}
                    style={{ cursor: uso === 'Particular' ? 'auto' : 'pointer' }}
                  >
                    <div 
                      className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-circle-inner"
                      style={{ transform: uso === 'Particular' ? 'scale(1)' : 'scale(0)' }}
                    />
                </div>
                <p className="ofertaPersonalizada-container-main-inputList-optionWrapper-options-item-text bold">Particular</p>
              </div>
            </div>
          </div>
          <CustomTextInput
            label='Metros cuadrados (m²)'
            required
            changeText={(e: string) => setMetros(e.replace(/[^0-9]+/g, ''))}
            value={metros}
            success={metros !== ''}
          />
          <div className="ofertaPersonalizada-container-main-inputList-selectorWrapper">
            <p className="ofertaPersonalizada-container-main-inputList-selectorWrapper-title bold">Tipo de cobertura&nbsp;<ul>*</ul></p>
            <SelectorSearcher
              widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
              id='Selector Tipo de cobertura'
              list={coberturaList}
              onValueSelected={(a: any) => setCobertura(a)}
              selectedItem={coberturaList ? coberturaList?.filter((a: any) => a.codigo == cobertura?.codigo)[0]?.descripcion : ''}
              placeholder='Seleccionar'
              deleteSelectedItem={() => setCobertura(null)}
              noSearch
            /> 
          </div>
        </div>
        <div className="ofertaPersonalizada-container-main-buttonWrapper">
          <MainButton
            id='ENVIAR'
            fill
            color='var(--secondary500)'
            text='ENVIAR'
            disabled={isDisabled()}
            onButtonPressed={() => {
              const data = {

                nombre: nombre,
                apellido: apellido,
                celular: celular,
                email: email,

                localidad: localidad,
                calle: calle,
                nro: nro,
                piso: piso,
                dpto: dpto,
                vivienda: vivienda,
                
                uso: uso,
                metros: metros,
                cobertura: cobertura?.descripcion

              }
              V2_postGenericForm('personal_offer', data, navigate, props.setLoading)
            }}
          />
        </div>
      </div>
      {!admin && <Footer/>}
    </div>
  )
}

export default OfertaPersonalizada;